import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { AddRawComponent } from 'src/app/Components/raw/PopUpRaw/add-raw/add-raw.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-view-part',
  templateUrl: './view-part.component.html',
  styleUrls: ['./view-part.component.css']
})
export class ViewPartComponent implements OnInit {
  part = new PartTracker();
  showNoImageMessage: boolean;

  constructor(
    public dialogRefaddPart: MatDialogRef<ViewPartComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private angularFireAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.part =  cloneDeep(data);
    this.showNoImageMessage = !this.part.UnfoldURL && !this.part.DrawingURL && !this.part.PhotoURL;
  }

  ngOnInit() {}

  view(url) {
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  onNoClick(){
    this.dialogRefaddPart.close();
  }
}
