import { Schedule } from './../Object_Classes/Schedule/Schedule';
import { Machine } from './../Object_Classes/Machine/Machine';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  convertDateIntoYearMonthDay(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year.toString() + ("0" + month.toString()).slice(-2) + ("0" + day.toString()).slice(-2);
  }

  convertDateIntoYearMonth(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return year.toString() + ("0" + month.toString()).slice(-2);
  }

  convertDateIntoYearTwoDigitMonth(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return year.toString().substring(2) + ("0" + month.toString()).slice(-2);
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  convertDateIntoISO8601(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return year.toString() + "-" + ("0" + month.toString()).slice(-2) + "-" + ("0" + day.toString()).slice(-2);

  }

  convertDateIntoISO8601NoDay(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return year.toString() + "-" + ("0" + month.toString()).slice(-2);
  }

  convertDateIntoISO8601NoYear(date: Date){
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return day.toString() + "/" + month.toString();
  }

  convertDateIntoDayMonthYearWithDash(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return ("0" + day.toString()).slice(-2) + "-" + ("0" + month.toString()).slice(-2) + "-" + year.toString();
  }

  convertDateIntoDayMonthYear(date: Date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return ("0" + day.toString()).slice(-2) + ("0" + month.toString()).slice(-2) + year.toString();
  }

  checkCrushDate(startdatelist: Date[], enddatelist: Date[], machinelist: Machine[]){
    for (let i = 0; i < startdatelist.length; i++) {
      for (let j = 0; j < startdatelist.length; j++) {
        if(j===i)
          continue;
        if (startdatelist[j].getTime() >= startdatelist[i].getTime() &&
          startdatelist[j].getTime() <= enddatelist[i].getTime() &&
          machinelist[j].Machine_No === machinelist[i].Machine_No) {
          return true;
        }

        if (enddatelist[j].getTime() >= startdatelist[i].getTime() &&
          enddatelist[j].getTime() <= enddatelist[i].getTime() &&
          machinelist[j].Machine_No === machinelist[i].Machine_No) {
          return true;
        }
      }
    }
    return false;
  }

  validateSchedulingMachine(startDate: Date, enddDate: Date, Machine: Machine, ScheduleNo = null){

    for (let index = 0; index < Machine.Schedule_Track.length; index++) {

      if(Machine.Schedule_Track[index].Machine_Schedule_No === ScheduleNo)
        continue;
      if (startDate.getTime() >= Machine.Schedule_Track[index].DateFormatStart.getTime() &&
      startDate.getTime() <= Machine.Schedule_Track[index].DateFormatEnd.getTime()) {
      return true;
      }
      if (enddDate.getTime() >= Machine.Schedule_Track[index].DateFormatStart.getTime() &&
      enddDate.getTime() <= Machine.Schedule_Track[index].DateFormatEnd.getTime()) {
      return true;
      }
    }

    return false;
  }

  getAllInstancesOfDayInMonth(fordate, forday) {
    fordate.setDate(1);
    var start = getStartDay(fordate, forday), month = fordate.getMonth(), result = [start];

    while (fordate.getMonth() == month) {
      result.push(new Date(fordate.setDate(fordate.getDate()+7)));
    } 

    return result.slice(0,-1);

    function getStartDay(d, forday) {
      return d.getDay() != +forday ? (d.setDate(d.getDate() + 1), getStartDay(d, forday)) : new Date(d);
    }
  }

  getNextMonday(date = new Date()) {
    const dateCopy = new Date(date.getTime());
  
    const nextMonday = new Date(
      dateCopy.setDate(
        dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
      ),
    );
  
    return nextMonday;
  }
}
