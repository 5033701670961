import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder }  from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})
export class InputDialogComponent implements OnInit {

  form: FormGroup;
  value = "";
  message = "";

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InputDialogComponent>,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.message = data;
  }

  ngOnInit(){
    this.form = this.fb.group({
      quantity: ""
    });
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const outsources = this.form.get('quantity').value;

    if(outsources == null || outsources == ''){
      this.toast.error('Please Enter the Required Field', 'Please Enter');
      return;
    }
    else{
      this.dialogRef.close(outsources);
    }
  }
}
