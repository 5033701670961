import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-raw-materials-part',
  templateUrl: './raw-materials-part.component.html',
  styleUrls: ['./raw-materials-part.component.css']
})
export class RawMaterialsPartComponent implements OnInit {

  RawMaterial: any = [];

  quantity:any
  constructor(
    private dialogRef: MatDialogRef<RawMaterialsPartComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    this.RawMaterial =data.materials

  }

  ngOnInit(){

  }
  cancel() {
    this.dialogRef.close(false);
  }


}
