export class Staff {
  public StaffName = '';
  public Role = '';
  public Email = '';
  public StaffNo = '';
  public StaffID = null;
  public Card_ID = '';
  public Access = [];
  public Submodule = [];
  public UpdatedBy = '';
  public CreatedBy = '';
  public UpdatedDate: Date;
  public CreatedDate: Date;
  public password = '';
  public Schedule_Track: ScheduleTracker[] = [];
  public new: number;
  public bf: number;
  public left: number;
  public complete: number;
  public DateOnly: String = '';
}

export class ScheduleTracker {
  public ID: string;
  public PO_No: string;
  public JO_No: string;
  public Customer: string;
  public new: number;
  public bf: number;
  public left: number;
  public complete: number;
  public Billing_Address: string;
  public Delivery_Address: string;
  public Updated_By : string;
  public Updated_Date : Date;
  public Status : string;
  public HandledById : string;
  public HandledBy : string;
  public StaffName = '';
  public Role = '';
  public Email = '';
  public StaffNo = '';
  public StaffID = null;
}
