import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { NgxSpinnerService } from 'ngx-spinner';
import { RawMaterialOrder } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';

@Component({
  selector: 'app-receive-details',
  templateUrl: './receive-details.component.html',
  styleUrls: ['./receive-details.component.css']
})
export class ReceiveDetailsComponent implements OnInit {

  quantity: number;
  receivedDate = new Date();
  maxDate :Date;
  lotNumber: any;
  rack: any;
  rackNumber: any;
  invoice: any;
  packets: number = 0;
  rawdata: PartTracker;
  box: any;
  array: any;
  amount: any;
  disabledAll = false;
  disabledSome = false;
  details: number = 0;
  SO_NO: any = "";
  email: string;
  file: any;
  fb
  downloadURL: Observable<string>;
  
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  RawListTDO: RawMaterialOrder[] = [];

  constructor(
    private dialogRef: MatDialogRef<ReceiveDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private spinner : NgxSpinnerService
  ) {
    this.rawdata = data;
    this.SO_NO = this.rawdata.ID;
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
  }

  ngOnInit() {
    this.PORawController.search_TDO_Details_withStatusPending().then(data => {
      this.RawListTDO = data;
      this.spinner.hide();
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }
  
  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm this details?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        updates1[this.rawdata.SO_No + '/Part List/' + this.rawdata.ID + '/Part Quantity'] = this.rawdata.PO_Part_Qty;

        if(parseInt(this.rawdata.PO_Part_Qty.toString()) >= parseInt(this.rawdata.POQuantity.toString())){
          var current = new Date().getTime().toString()
          updates1[this.rawdata.SO_No + '/Part List/' + this.rawdata.ID + '/Status'] = "Complete";
          updates1[this.rawdata.SO_No + '/Part List/' + this.rawdata.ID + '/Schedule/' + current + '/Process'] = "Completed";
          updates1[this.rawdata.SO_No + '/Part List/' + this.rawdata.ID + '/Schedule/' + current + '/Created_By'] = this.email;
        }
        
        await this.db.database.ref('Purchase Order/').update(updates1);

        if(this.rawdata.JOStatus == "Outsourcing"){
          let tdo = this.RawListTDO.find(a => a.JO_No == this.rawdata.ID);
          var uid = uuidv4();

          if(tdo){
            let updates2 = {};
            updates2[tdo.TDOno + '/RawMaterials/' + tdo.PO_Order_ID + '/_Received_Quantity'] = tdo.PO_RawMaterial_Qty;
            updates2[tdo.TDOno + '/RawMaterials/' + tdo.PO_Order_ID + '/_PO_RawMaterial_Status'] = "Completed";
            updates2[tdo.TDOno + '/RawMaterials/' + tdo.PO_Order_ID + '/ReceivedDetails/' + uid + '/_Received_Date'] = new Date().toISOString();
            updates2[tdo.TDOno + '/RawMaterials/' + tdo.PO_Order_ID + '/ReceivedDetails/' + uid + '/_Received_Quantity'] = tdo.PO_RawMaterial_Qty;
            await this.db.database.ref('PO Outsource/').update(updates2);
          }
        }

        var snapshot = await this.db.database.ref('Purchase Order').child(this.rawdata.SO_No).child('Part List').once('value');
        var breakMe = false;

        if (snapshot.exists()) {
          snapshot.forEach((childSnapshot2) =>{
            if(breakMe == false) {
              if(childSnapshot2.child('Status').val() != 'Complete'){
                this.db.database.ref('Purchase Order/' + this.rawdata.SO_No + '/_Status').set("Incomplete");
                breakMe = true;
              }
              else{
                this.db.database.ref('Purchase Order/' + this.rawdata.SO_No + '/_Status').set("Complete");
              }
            }
          });
        }
        
        this.dialogRef.close(true);
      }
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
  }
}
