import { BoxShippingInfo } from '../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PartTracker, CheckOut, RawCheckOut } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';

@Component({
  selector: 'app-shipping-information',
  templateUrl: './shipping-information.component.html',
  styleUrls: ['./shipping-information.component.css']
})
export class ShippingInformationComponent implements OnInit {

  title:string;
  record: PartTracker;
  ship : BoxShippingInfo;

  constructor(
      private dialogRef: MatDialogRef<ShippingInformationComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private spinner: NgxSpinnerService
    ) {
      this.title = data.PO_Part_Name;
      this.record = data;


    }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }
  view(box){
    this.ship = box;

  }

}
