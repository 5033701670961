import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
@Component({
  selector: 'app-add-raw',
  templateUrl: './add-raw.component.html',
  styleUrls: ['./add-raw.component.css']
})
export class AddRawComponent implements OnInit {

  raw = new RawMaterialInfo();
  RawDB_controllers: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  rawTypes = [];
  rawCats = ['RAW MATERIALS', 'OUTSOURCING'];
  selectedType: any;
  list:RawMaterialInfo[]=[]
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<AddRawComponent>,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.list = data;
      this.setup();
    }

  close(): void {
    this.dialogRef.close();
  }

  setup(){
    this.RawDB_controllers.getRawTypeList().then(data=>{
      this.rawTypes = data;
      this.rawTypes = this.rawTypes.sort((a, b) => {
        return this.compare(a.value, b.value, true);
      })
    })
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  change(Rawtype){
    this.selectedType = Rawtype;
    if(Rawtype!="Carton")
      this.raw.Carton_Weight = "";
  }

}
