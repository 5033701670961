import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { SelectMachineComponent } from './select-machine/select-machine.component';
import { AddEventComponent } from './add-event/add-event.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, NgZone, EventEmitter } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { Calendar, CalendarOptions, createElement, FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { UpdateScheduleComponent } from './update-schedule/update-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { ScheduleTracker, Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import * as dayjs from 'dayjs';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import { ToastrService } from 'ngx-toastr';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DetailPOPageComponent } from '../purchaseOrder/detail-po/detail-po-page.component';
import { ScanDrawingComponent } from '../manufacturing/scan-drawing/scan-drawing.component';
import JsBarcode from 'jsbarcode';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { UpdatePartComponent } from '../parts/PopUpModal/update-part/update-part.component';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css']
})
export class SchedulingComponent implements AfterViewInit {
  email: string;
  role: string;
  index = 0;
  AccessList: String[] = [];
  Reportinglist: PartTracker[] = [];
  CloneReportingList: PartTracker[] = [];
  CloneReportingList2: PartTracker[] = [];
  IncompletePOList: PurchaseOrder[] = [];
  IncompletePOList2: PartTracker[] = [];
  ClonePOList2: PartTracker[] = [];
  ClonePOList: PurchaseOrder[] = [];

  totalbf: number = 0;
  totalnew: number = 0;
  totalcomplete: number = 0;
  totalleft: number = 0;

  isAdmin: boolean = false;
  moreThanOne: boolean = true;

  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  selectedStartDate2: Date = new Date();
  selectedEndDate2: Date = new Date();

  //Pagination Monitoring
  monitoringLength = 0;
  monitoringPageSize = 10;
  monitoringPageSizeOptions: number[] = [10, 25, 50];
  monitoringPageIndex = 0;
  monitoringOffset = this.monitoringPageSize * this.monitoringPageIndex;
  searchMonitor: string;
  sortMonitoredu = {
    active: '',
    direction: ''
  };
  @ViewChild('topMonitoringPaginator', { read: MatPaginator, static: true }) topMonitoringPaginator: MatPaginator;
  @ViewChild('bottomMonitoringPaginator', { read: MatPaginator, static: true }) bottomMonitoringPaginator: MatPaginator;

  machine: string;
  machinelist = [];
  eventlist = [];
  POlist: PurchaseOrder[] = [];
  JOList: Staff[] = [];
  JOList2: Staff[] = [];
  CloneJOList: ScheduleTracker[] = [];
  partController = new PartDB_controller(this.angularFireDatabase, this.storage,this.firestore);
  PODB_controller: PODB_controller = new PODB_controller(this.angularFireDatabase);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.angularFireDatabase, this.firestore);
  partDB_controllers: PartDB_controller = new PartDB_controller(this.angularFireDatabase,this.storage,this.firestore);

  count = 0;
  selected: any;
  minDate: Date;
  maxDate: moment.Moment;
  startDate: Date = new Date();
  endDate: Date = new Date();
  minDate2: Date;
  maxDate2: moment.Moment;
  startDate2: Date = new Date();
  endDate2: Date = new Date();

  //Pagination
  JOList3: PurchaseOrder[] = [];
  CloneJOList3: PurchaseOrder[] = [];
  length3 = 0;
  pageSize3 = 10;
  pageSizeOptions3: number[] = [10, 25, 50];
  pageIndex3 = 0;
  offset3 = this.pageSize3 * this.pageIndex3;
  search3: string;
  sorted3 = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator3', { read: MatPaginator, static: false }) topPaginator3: MatPaginator;
  @ViewChild('bottomPaginator3', { read: MatPaginator, static: false }) bottomPaginator3: MatPaginator;

  //Pagination Monitoring
  monitoringLength2 = 0;
  monitoringPageSize2 = 10;
  monitoringPageSizeOptions2: number[] = [10, 25, 50];
  monitoringPageIndex2 = 0;
  monitoringOffset2 = this.monitoringPageSize2 * this.monitoringPageIndex2;
  searchMonitor2: string;
  sortMonitoredu2 = {
    active: '',
    direction: ''
  };
  @ViewChild('topMonitoringPaginator2', { read: MatPaginator, static: false }) topMonitoringPaginator2: MatPaginator;
  @ViewChild('bottomMonitoringPaginator2', { read: MatPaginator, static: false }) bottomMonitoringPaginator2: MatPaginator;

  constructor(
    private angularFireDatabase: AngularFireDatabase,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private userinfoSrv:UserInfoService,
    private dateService: DateFormatService,
    private db: AngularFireDatabase,
    private toast: ToastrService,
  ) {
    this.angularFireAuth.authState.subscribe(async auth => {
      this.email = auth.email;
    });

    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role;
    });

    this.userinfoSrv.currentSubmodules.subscribe(role=>{
      this.AccessList = role;
    });

    /*this.selectedStartDate.setDate(this.selectedStartDate.getDate() - 1);
    this.selectedEndDate.setDate(this.selectedEndDate.getDate() - 1);
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.endDate.setDate(this.endDate.getDate() - 1);*/
  }

  ngOnInit(): void {
    this.spinner.show();

    this.PODB_controller.search_PO_forDrawingReport().then(data => {
      this.Reportinglist = data;
      this.spinner.hide();
    });

    this.PODB_controller.search_JO_withStatusDrawingReviwed().then(async data => {
      this.JOList3 = data;

      await this.JOList3.sort((a,b)=>{
        return a.Longest_Date.getTime() - b.Longest_Date.getTime();
      });

      this.length3 = this.JOList3.length;
      this.CloneJOList3 = this.JOList3.slice();
      this.limitList3();
      this.spinner.hide();
    });

    this.setup();
    this.setup2();
  }

  ngAfterViewInit(): void {
    this.spinner.show();

    this.PODB_controller.getPOList().then(data => {
      this.POlist = data;
    });

    this.StaffDB_controller.getStaffList().then(async data => {
      await data.forEach(staff=>{
        //if((this.role == 'Adminstrator' || this.role == 'Director')){
        if((this.role == 'Adminstrator' || this.role == 'Director') && staff.Role == 'Engineer'){
          this.machinelist.push(staff);
        }
        else if(this.role != 'Adminstrator' && staff.Email == this.email){
          this.machinelist.push(staff);
        }
      });

      if((this.role == 'Adminstrator' || this.role == 'Director')){
        this.getSchedule(this.machinelist[0], 0);
      }
      else if(this.role != 'Adminstrator'){
        this.machinelist.forEach((data, index) => {
          if(data.Email == this.email){
            this.getSchedule(this.machinelist[index], index);
          }
        });
      }
    });

    this.StaffDB_controller.getStaffListwithSchedule().then(async data => {
      this.JOList = data;
      this.monitoringLength = this.JOList.length;
      this.CloneJOList = this.JOList[this.JOList.length - 1].Schedule_Track;

      this.JOList[this.JOList.length - 1].Schedule_Track.forEach(scheduleTracker => {
        // Sum up the values
        this.totalbf += scheduleTracker.bf;
        this.totalleft += scheduleTracker.left;
        this.totalnew += scheduleTracker.new;
        this.totalcomplete += scheduleTracker.complete;
      });

      this.limitList();
      this.ChangeDateDefault();
      this.spinner.hide();
    });
  }

  checkRight(module){
    if(this.role == 'Adminstrator' || this.role == 'Director' || this.role == 'Planner'){
      return true;
    }
    else{
      return this.AccessList.includes(module);
    }
  }

  setup(){
    this.spinner.show();
    this.IncompletePOList = [];

    this.PODB_controller.search_PO_withStatusDrawingReview().then(data => {
      this.IncompletePOList = data;

      this.IncompletePOList.sort((a,b)=>{
        return a.Longest_Date.getTime() - b.Longest_Date.getTime();
      });

      this.monitoringLength2 = this.IncompletePOList.length;
      this.ClonePOList = this.IncompletePOList.slice();
      this.limitMonitorList();
      this.spinner.hide();
    });
  }

  setup2(){
    this.spinner.show();
    this.IncompletePOList2 = [];

    return this.PODB_controller.search_JO_withStatusDrawingReview().then(data => {
      this.IncompletePOList2 = data;
      //this.monitoringLength2 = this.IncompletePOList2.length;
      this.ClonePOList2 = this.IncompletePOList2.slice();
      //this.limitMonitorList();
      this.spinner.hide();
    });
  }

  ScanDrawing(){
    const dialogRef = this.dialog.open(ScanDrawingComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.setup();
      }
    });
  }

  drawingReleased(){
    this.spinner.show();
    this.PODB_controller.search_JO_withStatusDrawingReviwed().then(async data => {
      this.JOList3 = data;

      await this.JOList3.sort((a,b)=>{
        return a.Longest_Date.getTime() - b.Longest_Date.getTime();
      });

      this.length3 = this.JOList3.length;
      this.CloneJOList3 = this.JOList3.slice();
      this.limitList3();
      this.spinner.hide();
    });
  }

  ReleaseDrawing(machine: PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm received drawing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        let updates1 = {};
        var current = new Date().getTime().toString();
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Status'] = "Drawing Released";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Process'] = "Drawing Released";
        updates1[machine.SO_No + '/Part List/' + machine.ID + '/Schedule/' + current + '/Created_By'] = this.email;
        await this.angularFireDatabase.database.ref('Purchase Order/').update(updates1);
        this.drawingReleased();
      }
    });
  }

  getSchedule(po: Staff, ind) {
    this.index = ind;
    //this.ClonePOList2 = this.IncompletePOList2.filter(u => u.AssignTo == po.StaffID);
    this.ClonePOList2 = this.IncompletePOList2;
    this.spinner.hide();
  }

  AddEvent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '95vw';

    const position = {
      top: '1%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddEventComponent, dialogConfig).afterClosed().subscribe(result => {
      this.spinner.show();
      this.getSchedule(this.machinelist[this.index], this.index);
    });

  }

  selectMachine(value) {
    this.machine = value;
    this.spinner.show();
    this.index = this.machinelist.indexOf(this.machinelist.find(a => a.StaffID == value));
    this.getSchedule(this.machinelist.find(a => a.StaffID == value), this.index);
  }

  printJT(machine: PartTracker, PO_No, Customer_PO, Person_In_Charge){
    this.spinner.show();
    $("#forPrintJT").find("remarksTP").val('');

    $("#forPrintJT").find("#description").text(machine.PO_Part_Name);
    $("#forPrintJT").find("#salesOrder").html(machine.ID);
    $("#forPrintJT").find("#partNo").text(machine.PO_Part_No);
    $("#forPrintJT").find("#poNo").text(Customer_PO);
    $("#forPrintJT").find("#quantity").text(machine.POQuantity + " " + (machine.UOM != null ? machine.UOM : ''));
    $("#forPrintJT").find("#projectOwner").text(Person_In_Charge);
    $("#forPrintJT").find("#dateReleased").text((machine.StartDate.getDate() < 10 ? 0 + machine.StartDate.getDate().toString() : machine.StartDate.getDate().toString()) + "/" + ((machine.StartDate.getMonth() + 1) < 10 ? 0 + (machine.StartDate.getMonth() + 1).toString() : (machine.StartDate.getMonth() + 1).toString()) + "/" + machine.StartDate.getFullYear().toString());
    $("#forPrintJT").find("#etd").text((machine.EndDate.getDate() < 10 ? 0 + machine.EndDate.getDate().toString() : machine.EndDate.getDate().toString())   + "/" + ((machine.EndDate.getMonth() + 1) < 10 ? 0 + (machine.EndDate.getMonth() + 1).toString() : (machine.EndDate.getMonth() + 1).toString()) + "/" + machine.EndDate.getFullYear().toString());

    $("#forPrintJT3").find("#drawingNo").text(machine.PO_Part_No);
    $("#forPrintJT3").find("#footer").text("RCET-QA-FR-023-02");

    if(machine.Process!=null && machine.Process.includes("POWDER COATING")){
      $("#forPrintJT").find("#remarksTP").html(machine.Outsources.join(', '));
    }
    else if(machine.Process!=null && machine.Process.includes("TREATMENT")){
      $("#forPrintJT").find("#remarksTP").html(machine.Outsources.join(', '));
    }

    JsBarcode("#barcode", machine.ID, {
      height:25,
      width:1,
      displayValue: false
    });

    setTimeout(function(){
      var divContents = $("#forPrintJT").html();
      var divContents2 = $("#forPrintJT2").html();
      var divContents3 = $("#forPrintJT3").html();
      var printWindow = window.open('', '', 'height=800,width=800');
      printWindow.document.write('<html><head><title>Test Print</title>')
      printWindow.document.write('<style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>');
      printWindow.document.write(divContents);

      printWindow.document.write(divContents3);
      //printWindow.document.write('<p style="page-break-after:always;"></p>');
      printWindow.document.write(divContents2);
      // printWindow.document.write('<img src="../../../../assets/JT_Page2.png" height="150%" width="100%"/>');
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      setTimeout(function(){printWindow.focus();printWindow.print();printWindow.close();}, 500);
    }, 1000);
    this.spinner.hide();
  }

  refresh(){
    this.setup();
  }

  refreshPlanning(){
    this.setup2().then(() => {
      if((this.role == 'Adminstrator' || this.role == 'Director')){
        this.getSchedule(this.machinelist[this.index], this.index);
      }
      else if(this.role != 'Adminstrator'){
        this.machinelist.forEach((data, index) => {
          if(data.Email == this.email){
            this.getSchedule(this.machinelist[index], this.index);
          }
        });
      }
    });
  }

  refreshReport(){
    this.StaffDB_controller.getStaffListwithSchedule().then(async data => {
      this.JOList = data;
      this.monitoringLength = this.JOList.length;
      this.CloneJOList = this.JOList[this.JOList.length - 1].Schedule_Track;

      this.JOList[this.JOList.length - 1].Schedule_Track.forEach(scheduleTracker => {
        // Sum up the values
        this.totalbf += scheduleTracker.bf;
        this.totalleft += scheduleTracker.left;
        this.totalnew += scheduleTracker.new;
        this.totalcomplete += scheduleTracker.complete;
      });

      this.limitList();
      this.ChangeDateDefault();
      this.spinner.hide();
    });
  }

  sortPOList() {
    if (!this.sortMonitoredu.active || this.sortMonitoredu.direction === '') {
      return;
    }
    this.ClonePOList = this.IncompletePOList.sort((a, b) => {
      const isAsc = this.sortMonitoredu2.direction === 'asc';
      switch (this.sortMonitoredu2.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'cus': return this.compareDate(a.Customer, b.Customer, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
        default: return 0;
      }
    });
  }

  limitMonitorList() {
    this.ClonePOList = this.ClonePOList.slice(this.monitoringOffset2, (this.monitoringOffset2 + this.monitoringPageSize2));
  }

  DynamicSearchMonitoring2(partNum: string): void {
    this.searchMonitor2 = partNum;
    this.ClonePOList = this.IncompletePOList.filter(u =>{
      const flag = (String(u.PO_No).toLowerCase().includes(this.searchMonitor2.toLowerCase()) ||  String(u.Customer).toLowerCase().includes(this.searchMonitor2.toLowerCase()));

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
          if((part.PO_Part_No.toLowerCase().includes(this.searchMonitor2.toLowerCase())|| (part.PO_Part_Name.toLowerCase().includes(this.searchMonitor2.toLowerCase()))))
            return true;
      }

      return flag;
    });

    this.monitoringLength2 = this.ClonePOList.length;
    this.sortJOList();
    this.limitMonitorList();
    this.topMonitoringPaginator2.firstPage()
    this.bottomMonitoringPaginator2.firstPage()
  }

  sortData2(sort: Sort) {
    this.sortMonitoredu2 = sort;
    this.ClonePOList = this.IncompletePOList.slice();
    if (this.searchMonitor2) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchMonitor2) {
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      return;
    }

    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortMonitoredu2.direction === 'asc';
      switch (this.sortMonitoredu2.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'Created': return this.compareDate(a.Created_Date, b.Created_Date, isAsc);
         default: return 0;
      }
    });
    this.limitList();
  }

  viewDetail(PO){
    const dialogRefupdatePart = this.dialog.open(DetailPOPageComponent, {
      data: PO,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      autoFocus: false
    });

    dialogRefupdatePart.afterClosed().subscribe(result => {
      this.setup();
    });
  }

  async viewPartDetail(PO_Part: PartTracker){
    var updatePart = new Part();
    updatePart = await this.partDB_controllers.search_Part(PO_Part.PO_Part_ID);
    const dialogRefupdatePart = this.dialog.open(UpdatePartComponent, {
      data: updatePart,
      width: '90%',
      height: '90%',
    });

    dialogRefupdatePart.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        updatePart = result;
        this.spinner.show();
        await this.partDB_controllers.add_or_update_Part(updatePart);
        setTimeout(() => {
          this.toast.success(updatePart.Part_Name + " has been updated successfully!","Updated Successfully")
          this.setup();
        }, 2000);
      }
    });
  }

  viewPartDetails(PO_Part: PartTracker){
    var staff = this.machinelist.find(a=>a.StaffID == PO_Part.AssignTo);

    //if(staff){
      //var scheduleId = staff.Schedule_Track.find(b=>b.JO_No===PO_Part.ID);
      
      const d = {
        po: PO_Part.SO_No,
        part: PO_Part.PO_Part_Name,
        jo: PO_Part.ID,
        partID: PO_Part.PO_Part_ID,
        scheduleId: '-',
        staffId: PO_Part.AssignTo || '-'
      }
  
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = '80%';
      dialogConfig.width = '80%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        id: PO_Part.ID,
        info: JSON.stringify(d),
      }
      this.dialog.open(UpdateScheduleComponent, dialogConfig).afterClosed().subscribe(result => {
        this.spinner.show();
        this.refreshPlanning();
      });
    //}
  }

  viewDrawing(url){
    window.open(url, '_blank');
  }

  validate(s){
    return typeof(s) === 'string'? true:false;
  }

  /*DynamicSearchMonitoring(partNum: string): void {
    this.searchMonitor = partNum;
    this.CloneJOList = this.JOList.filter(u =>{
      const flag = (String(u.StaffName).toLowerCase().includes(this.searchMonitor.toLowerCase()));
      return flag;
    });

    this.monitoringLength = this.CloneJOList.length;
    this.sortJOList();
    this.limitList();
    this.topMonitoringPaginator.firstPage()
    this.bottomMonitoringPaginator.firstPage()
  }*/

  sortJOList() {
    if (!this.sortMonitoredu2.active || this.sortMonitoredu2.direction === '') {
      return;
    }
    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortMonitoredu2.direction === 'asc';
      switch (this.sortMonitoredu2.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        default: return 0;
      }
    });
  }

  /*sortData(sort: Sort) {
    this.sortMonitoredu = sort;
    this.CloneJOList = this.JOList.slice();

    if (this.searchMonitor) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchMonitor) {
      this.CloneJOList = this.JOList.slice();
      this.limitList();
      return;
    }

    this.CloneJOList = this.CloneJOList.sort((a, b) => {
      const isAsc = this.sortMonitoredu.direction === 'asc';
      switch (this.sortMonitoredu.active) {
        case 'staff': return this.compare(a.StaffName, b.StaffName, isAsc);
        default: return 0;
      }
    });
    this.limitList();
  }*/

  monitoringPaginator2(pageEvent: PageEvent) {
    this.monitoringPageSize2 = pageEvent.pageSize;
    this.monitoringPageIndex2 = pageEvent.pageIndex;
    this.monitoringOffset2 = this.monitoringPageSize2 * this.monitoringPageIndex2;
    var offset2 = this.monitoringPageSize2 * (this.monitoringPageIndex2 + 1);

    if(offset2 >= pageEvent.length){
      this.topMonitoringPaginator2.lastPage();
      this.bottomMonitoringPaginator2.lastPage();
    }
    else if(this.monitoringOffset2 <= 0){
      this.topMonitoringPaginator2.firstPage();
      this.bottomMonitoringPaginator2.firstPage();
    }
    else{
      if (this.topMonitoringPaginator2.pageIndex < this.monitoringPageIndex2) {
        this.topMonitoringPaginator.nextPage();
      } 
      else if (this.topMonitoringPaginator2.pageIndex > this.monitoringPageIndex2) {
        this.topMonitoringPaginator2.previousPage();
      }
  
      if (this.bottomMonitoringPaginator2.pageIndex < this.monitoringPageIndex2) {
        this.bottomMonitoringPaginator2.nextPage();
      } 
      else if (this.bottomMonitoringPaginator2.pageIndex > this.monitoringPageIndex2) {
        this.bottomMonitoringPaginator2.previousPage();
      }
    }
    
    if (this.searchMonitor2) {
      //this.DS2();
    } 
    else {
      this.ClonePOList = this.IncompletePOList.slice();
    }
    //this.fs();
    this.sortJOList();
    this.limitMonitorList();
  }

  DS() {
    /*this.CloneJOList = this.JOList.filter(u =>{
      const flag = (String(u.StaffName).toLowerCase().includes(this.searchMonitor.toLowerCase()));
      return flag
    });
    this.monitoringLength = this.CloneJOList.length;*/
  }

  /*DS2() {
    this.CloneJOList = this.JOList.filter(u =>{
      const flag = (String(u.StaffName).toLowerCase().includes(this.searchMonitor2.toLowerCase()));
      return flag
    });
    this.monitoringLength2 = this.CloneJOList.length;
  }*/

  paginator3(pageEvent: PageEvent) {
    this.pageSize3 = pageEvent.pageSize;
    this.pageIndex3 = pageEvent.pageIndex;
    this.offset3 = this.pageSize3 * this.pageIndex3;

    if (this.topPaginator3.pageIndex < this.pageIndex3) {
      this.topPaginator3.nextPage();
    } else if (this.topPaginator3.pageIndex > this.pageIndex3) {
      this.topPaginator3.previousPage();
    }

    if (this.bottomPaginator3.pageIndex < this.pageIndex3) {
      this.bottomPaginator3.nextPage();
    } else if (this.bottomPaginator3.pageIndex > this.pageIndex3) {
      this.bottomPaginator3.previousPage();
    }

    if (this.search3) {
      this.DS3();
    } else {
      this.CloneJOList3 = this.JOList3.slice();
    }

    //this.fs();
    this.sortPOList3();
    this.limitList3();
  }

  DS3() {
    this.CloneJOList3 = this.JOList3.filter(u =>{
      const flag = String(u.PO_No).toLowerCase().includes(this.search3.toLowerCase())

      for (const part of u.PO_Part_List) {
        if(part.PO_Part_No && part.PO_Part_Name)
        if((part.PO_Part_No.toLowerCase().includes(this.search3.toLowerCase())
        || (part.PO_Part_Name.toLowerCase().includes(this.search3.toLowerCase()))))
            return true;
      }

      return flag;
    });

    this.length3 = this.CloneJOList3.length;
  }

  sortPOList3() {
    if (!this.sorted3.active || this.sorted3.direction === '') {
      return;
    }
    this.CloneJOList3 = this.CloneJOList3.sort((a, b) => {
      const isAsc = this.sorted3.direction === 'asc';
      switch (this.sorted3.active) {
        case 'poNO': return this.compare(a.PO_No, b.PO_No, isAsc);
        default: return 0;
      }
    });
  }

  limitList3(){
    this.CloneJOList3 = this.CloneJOList3.slice(this.offset3, (this.offset3 + this.pageSize3));
  }

  limitList() {
    this.CloneJOList = this.CloneJOList.slice(this.monitoringOffset, (this.monitoringOffset + this.monitoringPageSize));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getColor(quantityNeeded){
    if(quantityNeeded == 'Planned Material'){
      return "red";
    }
    else if(quantityNeeded == "Drawing Released"){
      return "violet"
    }
    else if(quantityNeeded == "Processing"){
      return "blue"
    }
    else if(quantityNeeded == "Complete"){
      return "green"
    }
    else if(quantityNeeded == "Outsourcing"){
      return "purple"
    }
  }

  getColorPanel(po:PurchaseOrder, index:any){
    if(po.Status == 'Incomplete' && po.PO_Part_List.length > 0){
      let flag = true;

      po.PO_Part_List.forEach(data => {
        if(data.JOStatus != 'Complete'){
          if(data.EndDate.getTime() - new Date().getTime() <= 259200000){
            flag = false;
            $('#SO' + index).attr('style', 'background-color: rgb(226 8 52 / 10%)');
            return 'rgb(226 8 52 / 25%)';
          }
          else if(data.EndDate.getTime() - new Date().getTime() <= 604800000){
            flag = false;
            $('#SO' + index).attr('style', 'background-color: rgb(226 222 8 / 10%)');
            return 'rgb(226 222 8 / 25%)';
          }
        }
      });

      if(flag){
        $('#SO' + index).attr('style', 'background-color: rgb(49 226 8 / 10%)');
        return 'rgb(49 226 8 / 25%)';
      }
    }
  }

  ChangeDateDefault() {
    const startDateObject = this.dateService.convertDateIntoYearMonthDay(new Date(this.startDate));
    const endDateObject = this.dateService.convertDateIntoYearMonthDay(new Date(this.endDate));

    var timeDifference = new Date(this.endDate).valueOf() - new Date(this.startDate).valueOf();

    // Calculate the number of milliseconds in a day
    var oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    // Check if the time difference is more than 1 day
    if (timeDifference > oneDayInMilliseconds) {
      this.moreThanOne = false;
    } else {
      this.moreThanOne = true;
    }

    this.JOList2 = this.JOList.filter(a => {
      return (
        a.DateOnly >= startDateObject && a.DateOnly <= endDateObject
      );
    });

    this.calculateTotals();
  }

  ChangeDate(formindex) {
    this.selectedStartDate = formindex.value;
    this.startDate = formindex.value;
    const startDateObject = this.dateService.convertDateIntoYearMonthDay(new Date(this.startDate));
    const endDateObject = this.dateService.convertDateIntoYearMonthDay(new Date(this.endDate));

    var timeDifference = new Date(this.endDate).valueOf() - new Date(this.startDate).valueOf();

    // Calculate the number of milliseconds in a day
    var oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    // Check if the time difference is more than 1 day
    if (timeDifference > oneDayInMilliseconds) {
      this.moreThanOne = false;
    } else {
      this.moreThanOne = true;
    }

    this.JOList2 = this.JOList.filter(a => {
      return (
        a.DateOnly >= startDateObject && a.DateOnly <= endDateObject
      );
    });

    this.calculateTotals();
  }

  ChangeDate2(formindex) {
    this.selectedEndDate = formindex.value;
    this.endDate = formindex.value;
    const startDateObject = this.dateService.convertDateIntoYearMonthDay(new Date(this.startDate));
    const endDateObject = this.dateService.convertDateIntoYearMonthDay(new Date(this.endDate));

    var timeDifference = new Date(this.endDate).valueOf() - new Date(this.startDate).valueOf();

    // Calculate the number of milliseconds in a day
    var oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (timeDifference > oneDayInMilliseconds) {
      this.moreThanOne = false;
    } else {
      this.moreThanOne = true;
    }

    this.JOList2 = this.JOList.filter(a => {
      return (
        a.DateOnly >= startDateObject && a.DateOnly <= endDateObject
      );
    });

    this.calculateTotals();
  }

  ChangeDate3(formindex) {
    this.selectedStartDate2 = formindex.value;
    this.startDate2 = formindex.value;
    const startDateObject: Date = new Date(this.startDate2);
    const endDateObject: Date = new Date(this.endDate2);

    this.CloneReportingList2 = this.Reportinglist.filter(a => {
      if(a.StartDrawing != null && a.EndDrawing != null){
        return (
          this.dateService.convertDateIntoYearMonthDay(a.StartDrawing) >=
            this.dateService.convertDateIntoYearMonthDay(startDateObject) &&
          this.dateService.convertDateIntoYearMonthDay(a.EndDrawing) <=
            this.dateService.convertDateIntoYearMonthDay(endDateObject)
        );
      }
      else{
        return false;
      }
    });
  }

  ChangeDate4(formindex) {
    this.selectedEndDate2 = formindex.value;
    this.endDate2 = formindex.value;
    const startDateObject: Date = new Date(this.startDate2);
    const endDateObject: Date = new Date(this.endDate2);

    this.CloneReportingList2 = this.Reportinglist.filter(a => {
      if(a.StartDrawing != null && a.EndDrawing != null){
        return (
          this.dateService.convertDateIntoYearMonthDay(a.StartDrawing) >=
            this.dateService.convertDateIntoYearMonthDay(startDateObject) &&
          this.dateService.convertDateIntoYearMonthDay(a.EndDrawing) <=
            this.dateService.convertDateIntoYearMonthDay(endDateObject)
        );
      }
      else{
        return false;
      }
    });
  }

  exportReportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    var html = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><title>Test Print</title><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/><style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {border:1pt; padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>';
    html += '<h1>Engineering Schedule </h1><br><p>DATE RELEASED: ' + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ' to ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</p>';
    html += '<table class="table table-bordered" border="1" style="border-collapse: collapse;"><thead>';
    html += '<tr><th scope="col">NO</th><th scope="col">DWG RECEIVED DATE</th><th scope="col">DWG COMPLETE DATE</th><th scope="col">DESCRIPTION</th><th scope="col">DRAWING NO.</th><th scope="col">SHEET METAL / COMPONENT <br> (SINGLE PC)</th><th scope="col">STRUCTURAL</th><th scope="col">CONCEPT / OTHERS</th><th scope="col">JO NO.</th><th scope="col">CUSTOMER</th></tr></thead><tbody>';
    
    this.CloneReportingList.forEach(async (po,index) => {
      html += '<tr><td>'+(index + 1).toString()+'</td><td>'+this.dateService.convertDateIntoDayMonthYearWithDash(po.StartDrawing)+'</td><td>';
      
      if(po.EndDrawing != null){
        html += this.dateService.convertDateIntoDayMonthYearWithDash(po.EndDrawing) + '</td>';
      }
      else{
        html += '</td>';
      }

      html += '<td>'+po.PO_Part_Name+'</td><td>'+po.PO_Part_No+'</td>';

      if(po.tow != null && po.tow != ''){
        if(po.tow == 'SHEET METAL'){
          html += '<td>/</td><td></td><td></td>';
        }
        else if(po.tow == 'STRUCTURAL'){
          html += '<td></td><td>/</td><td></td>';
        }
        else{
          html += '<td></td><td></td><td>/</td>';
        }
      }
      else{
        html += '<td></td><td></td><td></td>';
      }

      html += '<td>'+po.ID+'</td><td>'+po.CustomerName+'</td></tr>';
    });
    
    html += '</tbody></table></html>';

    let fileName = "Engineering_Schedule" + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ' to ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + ".xls";

    setTimeout(function(){
      var a = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel; base64';
      a.href = data_type + ', ' + btoa(unescape(encodeURIComponent(html)));
      a.download = fileName;
      a.click();
      return (a);
      //window.open('data:application/vnd.ms-excel,' + encodeURIComponent(html));
    }, 1000);
    
    this.spinner.hide();
  }

  exportReportToExcel2(){
    const exportInformation = [];
    this.spinner.show();

    var html = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><title>Test Print</title><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/><style>@media print {@page {margin-left: 0.5in;margin-right: 0.5in;margin-top: 0.1in;margin-bottom: 0.1in;}} table {width: 100%;border-collapse: collapse; table-layout: fixed;word-wrap: break-word;} .table th, .table td {border:1pt; padding: 0.50rem;vertical-align: top;border-top: 1px solid #dee2e6;} .table-bordered {border: 1px solid #000000;} .table-bordered th, .table-bordered td {border: 1px solid #000000;font-family: sans-serif;font-size: 12px;} .row {display: flex;flex-wrap: wrap;margin-top: 20px;margin-right: -15px;margin-left: -15px;} .col-md-4{position: relative;width: 33.333333%;} #footer {position: fixed;bottom: 0px;right: 0px;} #footer {position: fixed;bottom: 0px;right: 0px;}</style></head><body>';
    html += '<h1>Engineering Schedule </h1><br><p>DATE RELEASED: ' + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ' to ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + '</p>';
    html += '<table class="table table-bordered" border="1" style="border-collapse: collapse;"><thead>';
    html += '<tr><th scope="col">NO</th><th scope="col">DWG RECEIVED DATE</th><th scope="col">DWG COMPLETE DATE</th><th scope="col">DESCRIPTION</th><th scope="col">DRAWING NO.</th><th scope="col">SHEET METAL / COMPONENT <br> (SINGLE PC)</th><th scope="col">STRUCTURAL</th><th scope="col">CONCEPT / OTHERS</th><th scope="col">JO NO.</th><th scope="col">CUSTOMER</th><th scope="col">ENGINEER</th></tr></thead><tbody>';
    
    this.CloneReportingList2.forEach(async (po,index) => {
      html += '<tr><td>'+(index + 1).toString()+'</td><td>'+this.dateService.convertDateIntoDayMonthYearWithDash(po.StartDrawing)+'</td><td>';
      
      if(po.EndDrawing != null){
        html += this.dateService.convertDateIntoDayMonthYearWithDash(po.EndDrawing) + '</td>';
      }
      else{
        html += '</td>';
      }

      html += '<td>'+po.PO_Part_Name+'</td><td>'+po.PO_Part_No+'</td>';

      if(po.tow != null && po.tow != ''){
        if(po.tow == 'SHEET METAL'){
          html += '<td>/</td><td></td><td></td>';
        }
        else if(po.tow == 'STRUCTURAL'){
          html += '<td></td><td>/</td><td></td>';
        }
        else{
          html += '<td></td><td></td><td>/</td>';
        }
      }
      else{
        html += '<td></td><td></td><td></td>';
      }

      html += '<td>'+po.ID+'</td><td>'+po.CustomerName+'</td><td>'+po.AssignToName+'</td></tr>';
    });
    
    html += '</tbody></table></html>';

    let fileName = "Engineering_Schedule" + this.dateService.convertDateIntoISO8601(new Date(this.startDate)) + ' to ' + this.dateService.convertDateIntoISO8601(new Date(this.endDate)) + ".xls";

    setTimeout(function(){
      var a = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel; base64';
      a.href = data_type + ', ' + btoa(unescape(encodeURIComponent(html)));
      a.download = fileName;
      a.click();
      return (a);
      //window.open('data:application/vnd.ms-excel,' + encodeURIComponent(html));
    }, 1000);
    
    this.spinner.hide();
  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  convertToDateOnly(dateString: string): Date {
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are zero-based
    const day = parseInt(dateString.substring(6, 8), 10);
    return new Date(year, month, day);
  }

  calculateTotals() {
    this.CloneJOList = [];
    this.totalbf = 0;
    this.totalleft = 0;
    this.totalnew = 0;
    this.totalcomplete = 0;
  
    const staffTrackerMap = new Map<string, ScheduleTracker>(); // Use a map to store unique entries
  
    this.JOList2.forEach(item => {
      item.Schedule_Track.forEach(data => {
        this.totalbf += data.bf;
        this.totalleft += data.left;
        this.totalnew += data.new;
        this.totalcomplete += data.complete;
  
        let staffId = data.StaffID;
        let staffTracker: ScheduleTracker;
  
        // Check if the staffId already exists in the map
        if (staffTrackerMap.has(staffId)) {
          // If yes, get the existing staffTracker
          staffTracker = staffTrackerMap.get(staffId);
        } else {
          // If not, create a new staffTracker
          staffTracker = new ScheduleTracker();
          staffTracker.StaffID = staffId;
          this.StaffDB_controller.getStaffDetails(staffTracker);
          staffTrackerMap.set(staffId, staffTracker);
        }
  
        // Update the properties of the staffTracker
        staffTracker.bf = (staffTracker.bf || 0) + data.bf;
        staffTracker.left = (staffTracker.left || 0) + data.left;
        staffTracker.new = (staffTracker.new || 0) + data.new;
        staffTracker.complete = (staffTracker.complete || 0) + data.complete;
      });
    });
  
    // Convert the map values to an array
    this.CloneJOList = Array.from(staffTrackerMap.values());
  }  
}
