export class OldStock{
  public ID:string;
  public MaterialName: string;
  public MaterialID: string;
  public Quantity: number;
  public DeductedQuantity: number = 0;
  public _Last_Updated: Date;
  public _Created_Date: Date;
  public _Updated_By: string;
  public _Created_By: string;
  public assignedPO: oldStockPO[]= []
  public Location: string;
}

export class oldStockPO{
  public POName: string;
  public CheckOutQuantity: number;
  public MachineAssign: string;
  public PartNo: string;
  public CheckOutDate: Date;
}
