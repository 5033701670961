import { StocksComponent } from './PopUpModal/stocks/stocks.component';
import { ToastrService } from 'ngx-toastr';
import { RawMaterialsPartComponent } from './../../Shared/raw-materials-part/raw-materials-part.component';
import { DetailsComponent } from './PopUpModal/details/details.component';
import { UpdatePartComponent } from './PopUpModal/update-part/update-part.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { AddPartComponent } from './PopUpModal/add-part/add-part.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuidv4 } from 'uuid';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.css']
})
export class PartsComponent implements OnInit {

  partListWarehouse: Part[] = [];
  partlist: Part[] = [];
  onlyonepartlist: any = [];
  onlyonepartIDlist: any = [];
  AccessList: String[] = [];
  role:string;
  partDB_controllers: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);

  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  ClonePartList: Part[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  //Pagination warehouse
  lengthWarehouse = 0;
  pageSizeWarehouse = 10;
  pageIndexWarehouse = 0;
  offsetWarehouse = this.pageSizeWarehouse * this.pageIndexWarehouse;
  searchWarehouse: string;
  sorteduWarehouse = {
    active: '',
    direction: ''
  };
  ClonePartListWarehouse: Part[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginatorWarehouse: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginatorWarehouse: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private userinfoSrv:UserInfoService,
    private excelHelper: ExcelHelperService
  ) {
    this.userinfoSrv.currentRole.subscribe(role=>{
      this.role = role;
    });

    this.userinfoSrv.currentSubmodules.subscribe(role=>{
      this.AccessList = role;
    });
  }

  ngOnInit() {
    this.setup();
    /*this.partDB_controllers.getPartList().then(async data => {
      await data.forEach(async part => {
        if(!this.onlyonepartlist.includes(part.Part_No)){
          this.onlyonepartlist.push(part.Part_No);
          this.onlyonepartIDlist.push(part.ID);
          //let temp =this.partDB_controllers.searchPartByChild(part.Part_No);
        }
        else{
          console.log(part.ID);
          /*if(part.Process != null && part.Process.length>0){
            var partId = this.onlyonepartIDlist[this.onlyonepartlist.indexOf(part.Part_No)];
            this.onlyonepartIDlist[this.onlyonepartlist.indexOf(part.Part_No)] = part.ID;
            await this.partDB_controllers.delete_Part(partId);
          }
          else if(part.Outsource != null && part.Outsource != ""){
            var partId = this.onlyonepartIDlist[this.onlyonepartlist.indexOf(part.Part_No)];
            this.onlyonepartIDlist[this.onlyonepartlist.indexOf(part.Part_No)] = part.ID;
            await this.partDB_controllers.delete_Part(partId);
          }
          else if(part.Drawing_Number != null && part.Drawing_Number != ""){
            var partId = this.onlyonepartIDlist[this.onlyonepartlist.indexOf(part.Part_No)];
            this.onlyonepartIDlist[this.onlyonepartlist.indexOf(part.Part_No)] = part.ID;
            await this.partDB_controllers.delete_Part(partId);
          }
          else{
            await this.partDB_controllers.delete_Part(part.ID);
          }
        }
      });

      console.log(this.onlyonepartlist.length);
    });*/
  }

  refresh(){
    this.setup();
  }

  setup() {
    this.spinner.show();
    this.partDB_controllers.getPartList().then(data => {
      this.partlist = data;
      this.length = this.partlist.length;
      this.ClonePartList = this.partlist.slice();
      this.limitList();
      this.spinner.hide();
    });

    this.partDB_controllers.getNewPartList().then(data => {
      this.partListWarehouse = data;
      this.lengthWarehouse = this.partListWarehouse.length;
      this.ClonePartListWarehouse = this.partListWarehouse.slice();
      this.limitListWarehouse();
      this.spinner.hide();
    });
  }

  addPart(): void {
    const dialogRefaddPart = this.dialog.open(AddPartComponent, {
      width: '90%',
      height: '90%'
    });

    var newPart = new Part();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newPart = result;
        newPart.ID = uuidv4();
        this.spinner.show();
        this.partDB_controllers.add_or_update_Part(newPart);
        this.toast.success(newPart.Part_Name + " has been added successfully!","Added Successfully")
        this.setup();
      }
    });
  }

  viewPartDetail(part): void {
    var updatePart = new Part();
    updatePart = part;
    const dialogRefupdatePart = this.dialog.open(UpdatePartComponent, {
      data: updatePart,
      width: '90%',
      height: '90%',
    });

    dialogRefupdatePart.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        updatePart = result;
        this.spinner.show();
        await this.partDB_controllers.add_or_update_Part(updatePart);
        setTimeout(() => {
          this.toast.success(updatePart.Part_Name + " has been updated successfully!","Updated Successfully")
          this.setup();
        }, 2000);
      }
    });
  }

  viewNewPartDetail(part): void {
    var updatePart = new Part();
    updatePart = part;
    const dialogRefupdatePart = this.dialog.open(UpdatePartComponent, {
      data: updatePart,
      width: '90%',
      height: '90%',
    });

    dialogRefupdatePart.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        updatePart = result;
        this.spinner.show();
        await this.partDB_controllers.add_or_update_Part(updatePart);
        setTimeout(() => {
          this.toast.success(updatePart.Part_Name + " has been updated successfully!","Updated Successfully")
          this.setup();
        }, 2000);
      }
    });
  }

  deletePart(part: Part): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Do you really want to delete this part? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.partDB_controllers.delete_Part(part.ID);
        this.toast.success(part.Part_Name + " has been deleted successfully!","Deleted Successfully")
        this.setup();
      }
    })
  }

  DynamicSearchPartNumber(partNum: string): void {
    this.search = partNum;
    this.ClonePartList = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Part_Name).toLowerCase().includes(this.search.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.ClonePartList.length;
    this.sortPartList();
    this.limitList();
  }
  
  DS() {
    this.ClonePartList = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.search.toLowerCase())
      || String(u.Mould_Material).toLowerCase().includes(this.search.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.ClonePartList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePartList = this.partlist.slice();
    }
    this.sortPartList();
    this.limitList();
  }
  limitList() {
    this.ClonePartList = this.ClonePartList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPartList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePartList = this.ClonePartList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePartList = this.partlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePartList = this.partlist.slice();
      this.limitList();
      return;
    }

    this.ClonePartList = this.ClonePartList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
    this.limitList();
  }

  DynamicSearchPartNumberWarehouse(partNum: string): void {
    this.searchWarehouse = partNum;
    this.ClonePartListWarehouse = this.partlist.filter(u => String(u.Part_No).toLowerCase().includes(this.searchWarehouse.toLowerCase()));
    this.lengthWarehouse = this.ClonePartListWarehouse.length;
    this.sortPartListWarehouse();
    this.limitListWarehouse();
  }
  
  DSWarehouse() {
    this.ClonePartListWarehouse = this.partlist.filter(u =>
      String(u.Part_No).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      || String(u.Mould_Reference_No).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      || String(u.rackingNumber).toLowerCase().includes(this.searchWarehouse.toLowerCase())
      );
    this.lengthWarehouse = this.ClonePartListWarehouse.length;
  }

  paginatorWarehouse(pageEvent: PageEvent) {
    this.pageSizeWarehouse = pageEvent.pageSize;
    this.pageIndexWarehouse = pageEvent.pageIndex;
    this.offsetWarehouse = this.pageSizeWarehouse * this.pageIndexWarehouse;

    if (this.topPaginatorWarehouse.pageIndex < this.pageIndexWarehouse) {
      this.topPaginatorWarehouse.nextPage();
    } else if (this.topPaginatorWarehouse.pageIndex > this.pageIndexWarehouse) {
      this.topPaginatorWarehouse.previousPage();
    }
    if (this.bottomPaginatorWarehouse.pageIndex < this.pageIndexWarehouse) {
      this.bottomPaginatorWarehouse.nextPage();
    } else if (this.bottomPaginatorWarehouse.pageIndex > this.pageIndexWarehouse) {
      this.bottomPaginatorWarehouse.previousPage();
    }
    if (this.searchWarehouse) {
      this.DSWarehouse();
    } else {
      this.ClonePartListWarehouse = this.partlist.slice();
    }
    this.sortPartListWarehouse();
    this.limitListWarehouse();
  }

  limitListWarehouse() {
    this.ClonePartListWarehouse = this.ClonePartListWarehouse.slice(this.offsetWarehouse, (this.offsetWarehouse + this.pageSizeWarehouse));
  }

  sortPartListWarehouse() {
    if (!this.sorteduWarehouse.active || this.sorteduWarehouse.direction === '') {
      return;
    }
    this.ClonePartListWarehouse = this.ClonePartListWarehouse.sort((a, b) => {
      const isAsc = this.sorteduWarehouse.direction === 'asc';
      switch (this.sorteduWarehouse.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
  }

  sortDataWarehouse(sort: Sort) {
    this.sorteduWarehouse = sort;
    this.ClonePartListWarehouse = this.partlist.slice();
    if (this.searchWarehouse) {
      this.DSWarehouse();
    }
    if (!sort.active || sort.direction === '' && !this.searchWarehouse) {
      this.ClonePartListWarehouse = this.partlist.slice();
      this.limitListWarehouse();
      return;
    }

    this.ClonePartListWarehouse = this.ClonePartListWarehouse.sort((a, b) => {
      const isAsc = this.sorteduWarehouse.direction === 'asc';
      switch (this.sorteduWarehouse.active) {
        case 'part_number': return this.compare(a.Part_No, b.Part_No, isAsc);
        case 'part_name': return this.compare(a.Part_Name, b.Part_Name, isAsc);
        case 'stock': return this.compare(a.Stock_Quantity, b.Stock_Quantity, isAsc);
        case 'reserved': return this.compare(a.Reserved_Stock, b.Reserved_Stock, isAsc);
        case 'rackingNumber': return this.compare(a.rackingNumber, b.rackingNumber, isAsc);

        default: return 0;
      }
    });
    this.limitListWarehouse();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  viewRaw(materials) {
    console.log(materials);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {materials};

    this.dialog.open(RawMaterialsPartComponent, dialogConfig).afterClosed().subscribe(result => {

    })
  }

  view(part: Part, statement) {
    var info;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    switch (statement) {
      case 'cust':
        info = {
          title: 'Customer Preference',
          data: [{
            'label': 'Customer Name',
            'value': part.Customer_Name,
            'size': 'col-lg-6'

          },
          {
            'label': 'Purchase Order No.',
            'value': part.PO_No,
            'size': 'col-lg-6'

          },
          {
            'label': 'Part Price List Customer',
            'value': part.PPL_CUS,
            'size': 'col-lg-6'

          },
          {
            'label': 'Remark',
            'value': part.Remarks,
            'size': 'col-lg-12'

          },
          ]
        }
        break;
      case 'pack':
        info = {
          title: 'Packaging Information',
          data: [{
            'label': 'Box Size',
            'value': part.Box_Size,
            'size': 'col-lg-6'

          },
          {
            'label': 'Quantity in Box',
            'value': part.Box_Quantity,
            'size': 'col-lg-6'

          },
          ]
        }
        break;
      case 'secondary':
        info = {
          title: 'Process',
          data: [{
            'label': 'Blowing Cycle Time',
            'value': part.Cycle_Time,
            'size': 'col-lg-6'

          },
            {
            'label': 'Cutting Cycle Time',
            'value': part.Sec_Pro_1_Cycle_Time,
            'size': 'col-lg-6'

          },

          ]
        }
        break;
      case 'stock':
        dialogConfig.data = {
          type: 'stock',
          Part_No: part.Part_No
        }
        this.dialog.open(StocksComponent, dialogConfig)
        return;
      case 'reserved':
        dialogConfig.data = {
          type: 'reserved',
          stock: part.ReservedInfo,
          Part_No: part.Part_No
        }
        this.dialog.open(StocksComponent, dialogConfig)
        return;
    }
    this.dialog.open(DetailsComponent, {
      width: '80%',
      height: 'auto',
      data: info
    });

  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    for (const part of this.partlist) {
      const material = part.Raw_Material.find(r=>r.Raw_Type === 'Raw Material');
      const pigment = part.Raw_Material.find(r=>r.Raw_Type === 'Pigment');
      const batch = part.Raw_Material.find(r=>r.Raw_Type === 'Master Batch');

      const info ={
        "Part No" : part.Part_No,
        "Part Name": part.Part_Name,
        "Part Weight": part.Part_Weight || '-',
        "Runner Weight": part.Part_Runner_Weight || '-',
        "Model": part.Model|| 'N/A',
        "No of Cavity": part.Number_Cavity|| 'N/A',
        "Customer Licence": part.Customer_License|| 'N/A',
        "Duty Export":part.Duty_Export|| 'N/A',
        "Project Start Date":part.Project_Start_Date|| 'N/A',
        "Tarif Code":part.Traffic_Code|| 'N/A',
        "Tarif Code Name":part.Traffic_Code_Name|| 'N/A',
        "Stock Quantity": part.Stock_Quantity || 0,
        "Reserved Stock": part.Reserved_Stock || 0,
        "Customer Name":part.Customer_Name || 'N/A',
        "Purchase Order":part.PO_No || 'N/A',
        "Part Price List Mold":part.Mould_Price || '-',
        "Part Price List Customer":part.Part_Price || '-',
        "MOQ Remarks":part.Remarks|| 'N/A',
        "Mold Family Info":part.Mould_Family|| 'N/A',
        "Mold Information":part.Mould_Info|| 'N/A',
        "Mold Material":part.Mould_Material|| 'N/A',
        "Mold Maker":part.Mould_Maker_Name|| 'N/A',
        "Mold Maker Phone":part.Mould_Rack_Location|| 'N/A',
        "Mold Reference No":part.Mould_Reference_No|| 'N/A',
        "Mold Size":part.Mould_Size || 'N/A',
        "Cycle Time":part.Cycle_Time || '-',
        "Machine Tonnage":part.Machine_Tonnage || '-',
        "Machine No":part.Available_Machine || '-',
        "Carton Box":part.CartonBox.Material_Name || 'N/A',
        "Quantity In Box":part.Box_Quantity || 0,
        "Poly Bag/ Inner Box":part.PolyBag.Material_Name || part.InnerBox.Material_Name || "N/A",
        "Quantity In Poly Bag/ Inner Box": part.Polybag_Quantity || part.Quantity_InnerBox || 0,
        "Supplier Carton Packing": part.Supplier1,
        "Supplier Poly Bag/ Inner Box": part.Supplier6 || "N/A",
        "Material Ratio":material? material.Mat_Ratio : '-',
        "Customer Material":material?material.Customer_Material : '-',
        "SPEN Material":material?material.SPEN_Material:'-',
        "Customer Ral":material?material.Customer_RAL: '-',
        "Supplier 3":material?material.Supplier: "N/A",
        "Pigment/Colour": pigment?pigment.Material_Name: 'N/A',
        "Supplier 3(Pigment)":pigment?pigment.Supplier:'N/A',
        "Master Batch": batch?batch.Material_Name:'N/A',
        "Supplier 3(Batch)":batch?batch.Supplier:'N/A',
        "Secondary Process 1":part.Sec_Pro_1,
        "Cycle Time (Sec process 1)": part.Sec_Pro_1_Cycle_Time,
        "Usage 1":part.Usage1|| 'N/A',
        "Supplier 4":part.Supplier4 || 'N/A',
        "Secondary Process 2":part.Sec_Pro_2|| 'N/A',
        "Cycle Time (Sec process 2)":part.Sec_Pro_2_Cycle_Time|| 'N/A',
        "Usage 2":part.Usage2 || 'N/A',
        "Supplier 5":part.Supplier5 || 'N/A',
      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'part'+new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelWarehouse(){
    const exportInformation = [];
    this.spinner.show();
    for (const part of this.partlist) {
      const info ={
        "Part No" : part.Part_No,
        "Part Name": part.Part_Name,
        "Stock Quantity": part.Stock_Quantity || 0,
        "Reserved Stock": part.Reserved_Stock || 0,
        "Location": part.rackingNumber.slice(0, -1) || "N/A",

      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'partWarehouse'+new Date().getTime());
    this.spinner.hide();
  }

  checkRight(module){
    if(this.role == 'Adminstrator' || this.role == 'Director' || this.role == 'Planner'){
      return true;
    }
    else{
      return this.AccessList.includes(module);
    }
  }
}

