import { AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { Invoice, Invoice_PO_List, Invoice_PO_Part_List } from '../../Services/Object_Classes/Invoice/Invoice';
import { InvoiceDB_controller } from '../../Services/DB_Controller/InvoiceDB_controller';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { AddPackingComponent } from './add-packing/add-packing.component';
import { PackingList, PackingList_PO_List, PackingList_PO_Part_List } from '../../Services/Object_Classes/PackingList/PackingList';
import { PackingListDB_controller } from '../../Services/DB_Controller/PackingListDB_controller';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { UpdateInvoiceComponent } from './update-invoice/update-invoice.component';
import { UpdatePackingComponent } from './update-packing/update-packing.component';
import { pack } from 'html2canvas/dist/types/css/types/color';
import { GenerateOldLabelComponent } from './generate-old-label/generate-old-label.component';
import { GenerateQRCodeDialogComponent } from './generate-qrcode-dialog/generate-qrcode-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
})
export class InvoiceComponent implements OnInit {

  invoiceList: Invoice[] = [];
  packingList: PackingList[] = [];
  podb_Controller = new PODB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db, this.storage, this.firestore);
  invoice_controller = new InvoiceDB_controller(this.db);
  invoicePartList: Invoice_PO_Part_List[] = [];
  invoicePOList: Invoice_PO_List[] = [];
  packing_controller = new PackingListDB_controller(this.db,this.firestore);
  packingPartList: PackingList_PO_Part_List[] = [];
  packingPOList: PackingList_PO_List[] = [];
  invoiceFormat: any = /^INV\d{3}-\d{6}/;
  tempInvoiceNo: string = "testing";
  packingFormat: any = /^PKG\d{3}-\d{6}/;
  tempPackingNo: string = "testing";
  selectedInvoiceCurrency: string;

  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  CloneInvoicePOList: Invoice_PO_List[] = [];
  CloneInvoiceList: Invoice[] = [];
  ClonePackingPOList: PackingList_PO_List[] = [];
  ClonePackingList: PackingList[] = [];

  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;

  @ViewChild('topPaginatorinv', { read: MatPaginator, static: false }) topPaginatorinv: MatPaginator;
  @ViewChild('bottomPaginatorinv', { read: MatPaginator, static: false }) bottomPaginatorinv: MatPaginator;

  lengthinv = 0;
  pageSizeinv = 10;
  pageIndexinv = 0;
  offsetinv = this.pageSizeinv * this.pageIndexinv;
  searchinv: string;
  sorteduinv = {
    active: '',
    direction: ''
  };
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  ngOnInit() {
    this.setup();
  }

  refresh() {
    this.setup();
  }
  setup() {
    this.spinner.show();

    this.packing_controller.getPackingListList().then(data => {
      this.packingList = data;
      this.length = this.packingList.length;
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
    });
    this.invoice_controller.getInvoiceList().then(data => {
      this.invoiceList = data;
      this.CloneInvoiceList = this.invoiceList.slice();
      this.spinner.hide();
    });
  }

  viewInvoiceDetail(invoice) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      invoice: invoice,
    };
    this.dialog.open(UpdateInvoiceComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }

  viewPackingDetail(packingNumber) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seePacking: packingNumber,
    };
    this.dialog.open(UpdatePackingComponent, dialogConfig).afterClosed().subscribe(result => {
      setTimeout(() => {
        this.setup();
      }, 1000);

    });
  }

  deletePackingInvoice(packingNumber: any, invoiceNumber: any) {
    let deletePackingList = new PackingList;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Invoice/Packing';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.packingList.forEach(data => {
          if (data.PackingList_No == packingNumber) {
            deletePackingList = data;
          }
        });
        this.packing_controller.delete_PackingList(deletePackingList);
        this.invoice_controller.delete_Invoice(invoiceNumber);
        this.setup();
      }
    })

  }


  deletePacking(packing: PackingList) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Packing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.packing_controller.delete_PackingList(packing);
        this.setup();
      }
    })

  }

  deleteInvoice(invoice) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Invoice?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.invoice_controller.delete_Invoice(invoice);
        this.setup();
      }
    })

  }


  addInvoice() {
    this.generateInvoiceNumber();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };

    const list = this.packingList.filter(p => !p.AddedToInvoice);
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      tempInvoiceNo: this.tempInvoiceNo,
      packingList: list
    };
    this.dialog.open(AddInvoiceComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }

  addPacking() {
    this.generatePackingNumber();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.tempPackingNo;
    this.dialog.open(AddPackingComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }

  generateInvoiceNumber() {
    if (this.invoiceList.length > 0) {
      const clone = this.invoiceList.filter(i => i.Invoice_No.includes('INV'));

      let result = clone[clone.length - 1].Invoice_No.match(this.invoiceFormat);
      let mid3entries = "000";
      let last6entries = "000000";
      if (result) {
        let last6digits = parseInt(result[0].substring(7, 13));
        if (last6digits < 999999) {
          last6digits = last6digits + 1;
          if (last6digits < 10) {
            last6entries = "00000" + last6digits.toString();
          }
          else if (last6digits < 100) {
            last6entries = "0000" + last6digits.toString();
          }
          else if (last6digits < 1000) {
            last6entries = "000" + last6digits.toString();
          }
          else if (last6digits < 10000) {
            last6entries = "00" + last6digits.toString();
          }
          else if (last6digits < 100000) {
            last6entries = "0" + last6digits.toString();
          }
          else if (last6digits < 1000000) {
            last6entries = last6digits.toString();
          }

          let mid3digits = parseInt(result[0].substring(3, 6));
          if (last6digits == 999999) {
            if (mid3digits < 999) {
              mid3digits = mid3digits + 1;
              if (mid3digits < 10) {
                mid3entries = "00" + mid3digits.toString();
              }
              else if (mid3digits < 100) {
                mid3entries = "0" + mid3digits.toString();
              }
              else if (mid3digits < 1000) {
                mid3entries = mid3digits.toString();
              }
            }
          }
        }
        this.tempInvoiceNo = "INV" + mid3entries + "-" + last6entries;
      }
      else {
        this.tempInvoiceNo = "INV000-000000";
      }
    }
    else {
      this.tempInvoiceNo = "INV000-000000";
    }
  }

  generatePackingNumber() {
    if (this.packingList.length > 0) {
      const clone = this.packingList.filter(p => p.PackingList_No.includes('PKG'));
      let result = null
      if (clone.length > 0) {
        result = clone[clone.length - 1].PackingList_No.match(this.packingFormat);
      }
      let mid3entries = "000";
      let last6entries = "000000";
      if (result) {
        let last6digits = parseInt(result[0].substring(7, 13));
        if (last6digits < 999999) {
          last6digits = last6digits + 1;
          if (last6digits < 10) {
            last6entries = "00000" + last6digits.toString();
          }
          else if (last6digits < 100) {
            last6entries = "0000" + last6digits.toString();
          }
          else if (last6digits < 1000) {
            last6entries = "000" + last6digits.toString();
          }
          else if (last6digits < 10000) {
            last6entries = "00" + last6digits.toString();
          }
          else if (last6digits < 100000) {
            last6entries = "0" + last6digits.toString();
          }
          else if (last6digits < 1000000) {
            last6entries = last6digits.toString();
          }

          let mid3digits = parseInt(result[0].substring(3, 6));
          if (last6digits == 999999) {
            if (mid3digits < 999) {
              mid3digits = mid3digits + 1;
              if (mid3digits < 10) {
                mid3entries = "00" + mid3digits.toString();
              }
              else if (mid3digits < 100) {
                mid3entries = "0" + mid3digits.toString();
              }
              else if (mid3digits < 1000) {
                mid3entries = mid3digits.toString();
              }
            }
          }
        }
        this.tempPackingNo = "PKG" + mid3entries + "-" + last6entries;
      }
      else {
        this.tempPackingNo = "PKG000-000000";
      }
    }
    else {
      this.tempPackingNo = "PKG000-000000";
    }
  }


  DynamicSearch(searchValue: string): void {
    this.search = searchValue;

    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.search.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.search.toLowerCase()))
          return true;
      }
      return flag
    });


    this.length = this.ClonePackingList.length;
    this.sortPackingList();
    this.limitPackingList();
  }

  DS1() {
    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.search.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.search.toLowerCase()))
          return true;
      }
      return flag
    });

    this.length = this.ClonePackingList.length;
  }

  paginator1(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS1();
    } else {
      this.ClonePackingList = this.packingList.slice();
    }
    this.sortPackingList();
    this.limitPackingList();
  }

  limitPackingList() {
    this.ClonePackingList = this.ClonePackingList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPackingList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.customerID, b.customerID, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
  }

  sortPackingData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePackingList = this.packingList.slice();
    if (this.search) {
      this.DS1();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
      return;
    }

    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.Customer, b.Customer, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
    this.limitPackingList();
  }

  DynamicSearchInvoice(searchValue: string): void {
    this.search = searchValue;
    this.CloneInvoiceList = this.invoiceList.filter(u =>
      String(u.Invoice_No).toLowerCase().includes(this.search.toLowerCase()));
    this.lengthinv = this.CloneInvoiceList.length;
    this.sortInvoice();
    this.limitInvoice();
  }

  DS2() {
    this.CloneInvoiceList = this.invoiceList.filter(u =>
      String(u.Invoice_No).toLowerCase().includes(this.search.toLowerCase()));
    this.lengthinv = this.CloneInvoiceList.length;
  }



  paginator2(pageEvent: PageEvent) {
    this.pageSizeinv = pageEvent.pageSize;
    this.pageIndexinv = pageEvent.pageIndex;
    this.offsetinv = this.pageSizeinv * this.pageIndexinv;
    if (this.topPaginatorinv.pageIndex < this.pageIndexinv) {
      this.topPaginatorinv.nextPage();
    } else if (this.topPaginatorinv.pageIndex > this.pageIndexinv) {
      this.topPaginatorinv.previousPage();
    }
    if (this.bottomPaginatorinv.pageIndex < this.pageIndexinv) {
      this.bottomPaginatorinv.nextPage();
    } else if (this.bottomPaginatorinv.pageIndex > this.pageIndexinv) {
      this.bottomPaginatorinv.previousPage();
    }
    if (this.searchinv) {
      this.DS2();
    } else {
      this.CloneInvoiceList = this.invoiceList.slice();
    }
    this.sortInvoice();
    this.limitInvoice();
  }


  limitInvoice() {
    this.CloneInvoiceList = this.CloneInvoiceList.slice(this.offsetinv, (this.offsetinv + this.pageSizeinv));
  }


  sortInvoice() {
    if (!this.sorteduinv.active || this.sorteduinv.direction === '') {
      return;
    }
    this.CloneInvoiceList = this.CloneInvoiceList.sort((a, b) => {
      const isAsc = this.sorteduinv.direction === 'asc';
      switch (this.sorteduinv.active) {
        case 'invNo': return this.compare(a.Invoice_No, b.Invoice_No, isAsc);
        case 'totPrice': return this.compare(a.Total_Price, b.Total_Price, isAsc);
        case 'updateBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'updateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }



  sortInvoiceData(sort: Sort) {
    this.sorteduinv = sort;
    this.CloneInvoiceList = this.invoiceList.slice();
    if (this.searchinv) {
      this.DS2();
    }
    if (!sort.active || sort.direction === '' && !this.searchinv) {
      this.CloneInvoiceList = this.invoiceList.slice();
      this.limitInvoice();
      return;
    }

    this.CloneInvoiceList = this.CloneInvoiceList.sort((a, b) => {
      const isAsc = this.sorteduinv.direction === 'asc';
      switch (this.sorteduinv.active) {
        case 'invNo': return this.compare(a.Invoice_No, b.Invoice_No, isAsc);
        case 'totPrice': return this.compare(a.Total_Price, b.Total_Price, isAsc);
        case 'updateBy': return this.compare(a.Updated_By, b.Updated_By, isAsc);
        case 'updateDate': return this.compareDate(a.Updated_Date, b.Updated_Date, isAsc);
        default: return 0;
      }
    });
    this.limitInvoice();
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  previewPackingList() {

  }

  previewInvoice() {

  }

  GenerateLabel() {
    this.dialog.open(GenerateQRCodeDialogComponent, {
      width: '80%',
      height: '80%'
    });

  }

  GenerateOldLabel() {
    this.dialog.open(GenerateOldLabelComponent, {
      width: '80%',
      height: 'auto'
    });

  }

  exportToExcelPL() {
    const exportInformation = [];
    this.spinner.show();

    for (const p of this.packingList) {
      const info ={
        "PackingList No":p.PackingList_No || "-",
        "Customer":p.Customer || "-",
        "Destination":p.Destination || "-",
        "Shipping Date":p.Shipping_Date || "-",
        "Created By":p.Created_By,
        "Created Date":p.Created_Date,
        "Purchase Order List":p.PackingList_PO_List.map(a=>a.PO_No +' : '+ a.PackingList_PO_Part_List.map(b=>b.Part_No).join(',')).join('   |   '),

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO'+new Date().getTime());
    this.spinner.hide();

  }
}


