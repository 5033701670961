import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'prodSort',
    pure: false
})
export class SortProductionPerformancePipe implements PipeTransform {
    transform(items:any []): any {
      if(!items)
        return [];
      if(items.length===0)
          return [];
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.sort( (a,b) =>{
          return this.compareDate(a.from,b.from, false);
        });
    }
    compareDate(a, b, isAsc: boolean) {
      a = new Date(a);
      b = new Date(b);
      return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
    }
}
