import { NgxSpinnerService } from 'ngx-spinner';
import { ScanReceiveDetailsComponent } from './scan-receive-details/scan-receive-details.component';
import { PurchaseOrderRaw, RawMaterialOrder } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSelectionListChange, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-received-scan',
  templateUrl: './received-scan.component.html',
  styleUrls: ['./received-scan.component.css']
})
export class ReceivedScanComponent implements OnInit {
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  email: string;
  POlist: PurchaseOrderRaw[];
  matSelectPO: PurchaseOrderRaw[] = [];
  TDODetailsList: RawMaterialOrder[] = []
  addForm: FormGroup;
  selectedPO: PurchaseOrderRaw;
  outsourcelist: RawMaterialOrder[] = [];
  selectedRaws = [];
  detailsPO = [];
  IdList: any = [];
  searchPO: any;
  check = false;
  qrCodes = [];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  poNo: string;
  scanning: any = '';

  constructor(
    private dialogRef: MatDialogRef<ReceivedScanComponent>,
    private fb: FormBuilder,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private toast: ToastrService,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })

    this.PORawController.search_TDO_withStatusPending().then( data => {
      this.POlist = data;
      this.matSelectPO = this.POlist.slice();
      this.spinner.hide();
    });

    this.PORawController.search_TDO_Details_withStatusPending().then( data => {
      this.TDODetailsList = data;
      this.spinner.hide();
    });

    const POControl = new FormControl();
    const POfilterControl = new FormControl();

    POfilterControl.valueChanges.subscribe(() => {
      this.findPO(POfilterControl);
      if (this.POlist) {
        this.check = true;
      }
    });

    POControl.valueChanges.subscribe(async () => {
      if (POControl.value) {
        let jo = await this.POlist.find(a=>a.PO_No == POControl.value);

        await jo.PO_RawMaterials.forEach(jo => {
          this.outsources().push(this.newOutsourcesBarcode(jo.PO_Order_ID, jo.Supplier, jo.PO_RawMaterial_Name, jo.Part_Name, jo.PO_RawMaterial_Qty));
          this.outsourcelist.push(null);
        });
        //this.searchPO = POControl.value;
        //this.check = true;
      }
    });

    this.addForm = new FormGroup({
      POControl,
      POfilterControl,
      outsources: this.fb.array([])
    });
  }

  findPO(PO) {
    if (!this.POlist) { return; }
    const search = PO.value;
    this.matSelectPO = this.POlist.filter(p => p.PO_No.toLowerCase().includes(search.toLowerCase()));
    //this.selectedPO = this.matSelectPO.find(p => p.PO_No.toLowerCase() === (this.searchPO.toLowerCase()));
  }

  ngOnInit() {
    this.spinner.show();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async confirm() {
    const outsources = this.addForm.get('outsources').value;

    if(outsources == null || outsources.length <= 0){
      this.toast.error('Need At Least one outsource record', 'Please Add');
      return;
    }

    const addPOModel = {
      outsources: []
    };

    var flag = true;
    await outsources.forEach(async (element, index) => {
      if (!element.ReceivedQuantity) {
        this.toast.error('Outsource information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag=false;
      }

      let temp = await this.TDODetailsList.find(a => a.PO_Order_ID == this.IdList[index]);

      const updatePO={
        id: this.IdList[index],
        TDONo: temp.TDOno,
        status: parseFloat(element.ExpectedQuantity) !== parseFloat(element.ReceivedQuantity)? 'Still Pending': 'Completed',
        invoice: element.Remark || "",
        receivedQuantity: element.ReceivedQuantity,
        uid: uuidv4()
      }

      addPOModel.outsources.push(updatePO);
    });

    if(!flag)
      return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm received these TDOs?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        await this.PORawController.update_TDO(addPOModel, this.email);
        this.spinner.hide();
        this.dialogRef.close(true);
      }
    });
  }

  enterDetails(event : MatSelectionListChange) {
    if(event.option.selected) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = '60%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data =
      {
        event: event.option.value,
      };
      this.dialog.open(ScanReceiveDetailsComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          this.detailsPO.push(result);
        } else {
          event.option.selected = false;
        }
      });
    } else{
      this.removeDetails(event.option.value);
    }
  }

  outsources(): FormArray {
    return this.addForm.get('outsources') as FormArray;
  }

  newOutsourcesBarcode(id: any, sup: any, out: any, part: any, quant: any): FormGroup {
    this.IdList.push(id);
    this.scrollToBottom();

    return this.fb.group({
      Supplier: sup,
      OutsourceName: out,
      PartNo: part,
      ExpectedQuantity: quant,
      ReceivedQuantity: quant,
      Remark: ""
    });
  }

  /*newOutsources(): FormGroup {
    return this.fb.group({
      Supplier: "",
      OutsourceName: "",
      PartNo: "",
      ExpectedQuantity: 0,
      ReceivedQuantity: 0,
      Remark: ""
    });
  }

  addOutsources() {
    this.outsources().push(this.newOutsources());
    this.outsourcelist.push(null);
  }*/

  removeDetails(raw){
    this.detailsPO = this.detailsPO.filter(d => d.ID !== raw.PO_RawMaterial_ID);
  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  view(event,r,d){
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '60%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      data: r,
      value:d,
      status: 'View'
    };
    this.dialog.open(ScanReceiveDetailsComponent, dialogConfig)
  }

  scan(){
    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
    this.scanning = "Scanning.......";
  }

  async barcode(event){
    //this.isScanned = true;
    let jo = await this.TDODetailsList.find(a=>a.JO_No == event.target.value);
    this.outsources().push(this.newOutsourcesBarcode(jo.PO_Order_ID, jo.Supplier, jo.PO_RawMaterial_Name, jo.Part_Name, jo.PO_RawMaterial_Qty));
    this.outsourcelist.push(null);
    this.detailsPO.push(null);
    this.check = true;

    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
  }

  ReceivedQuant(event, formindex:0){
    const value = {
      ID: this.IdList[formindex],
      quantity: event.target.value.toString(),
      receivedDate: new Date(),
      invoice: ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('Remark').value || "",
      receivedQuantity: event.target.value,
      status: ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('ExpectedQuantity').value !== parseFloat(event.target.value)? 'Still Pending': 'Completed',
      uid: uuidv4()
    }

    this.detailsPO[formindex] = value;
  }

  RemarkChange(event, formindex:0){
    const value = {
      ID: this.IdList[formindex],
      quantity: event.target.value.toString(),
      receivedDate: new Date(),
      invoice: ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('Remark').value || "",
      receivedQuantity: event.target.value,
      status: ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('ExpectedQuantity').value !== parseFloat(event.target.value)? 'Still Pending': 'Completed',
      uid: uuidv4()
    }

    this.detailsPO[formindex] = value;
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }
}
