import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { SelectMachineSlotComponent } from '../../scheduling/add-event/select-machine-slot/select-machine-slot.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddPartComponent } from '../../parts/PopUpModal/add-part/add-part.component';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { WeightingDB_controller } from 'src/app/Services/DB_Controller/WeightingDB_controller';
import { QCRejectionComponent } from '../qc-rejection/qc-rejection.component';

@Component({
  selector: 'app-view-confirmation',
  templateUrl: './view-confirmation.component.html',
  styleUrls: ['./view-confirmation.component.css']
})
export class ViewConfirmationComponent implements OnInit {
  addPOModel: any;
  addPOArray: any[] = [];
  sampleSize: any;

  constructor(
    private dialogRef: MatDialogRef<ViewConfirmationComponent>,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner : NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.addPOModel = data;
    this.sampleSize = this.addPOModel.parts[0].samples.length;

    if(this.addPOModel.parts.length > 12){
      let rowNum = this.addPOModel.parts.length / 12;

      for(var i=0; i<rowNum; i++){
        let start = i * 12; 
        let end = start + 12;
        this.addPOArray.push(this.addPOModel.parts.slice(start, end));
      }
    }
    else{
      this.addPOArray.push(this.addPOModel.parts);
    }
  }

  ngOnInit() {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.addPOModel;

    this.dialog.open(QCRejectionComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(false);
      }
    });
  }

  counter(i: number) {
    return new Array(i);
  }
}
