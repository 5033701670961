import { InfoComponent } from './info/info.component';
import { SelectPOStockComponent } from './select-postock/select-postock.component';
import { Currency } from './../../../Shared/Currency';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { PackingListDB_controller } from '../../../Services/DB_Controller/PackingListDB_controller';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AngularFirestore } from '@angular/fire/firestore';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-add-packing',
  templateUrl: './add-packing.component.html',
  styleUrls: ['./add-packing.component.css']
})
export class AddPackingComponent implements OnInit {
  poNo = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  po_controller: PODB_controller = new PODB_controller(this.db);
  packing_controller: PackingListDB_controller = new PackingListDB_controller(this.db,this.firestore);
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  matSelectPO: PurchaseOrder[] = [];
  matSelectPart=[];
  addForm: FormGroup;
  clone = [];
  newPO: PurchaseOrder = new PurchaseOrder();
  shippingDate = new Date();
  shipDate = [];
  searchPart: any;
  searchPO: any;
  check = false;
  check2 = false;
  selectedParts: any[] = [];
  newPackingList: PackingList = new PackingList();
  email:string;
  @ViewChild('picker', {static: true}) picker: any;
  temporaryPackingNum: string = "";
  customerList: Customer[] = [];
  customer_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  searchCustomer: any;
  cloneCustomer : Customer[] = [];
  matSelectCustomer : Customer[] = [];


  currencyList = Currency;
  CompleteClonePO = [];
  public date: moment.Moment;

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  constructor(
    private dialogRef: MatDialogRef<AddPackingComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private firestore: AngularFirestore
  ) {

    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })
    this.temporaryPackingNum = data;
  }

  ngOnInit() {
    this.po_controller.search_PO_withStatusNotCompleted().then(data => {
      data.forEach(data2 => {
        data2.PO_Part_List.forEach(data3 => {
          if (data3.Added_TO_Packing == false) {
            if(this.matSelectPO.find(m=>m.PO_No === data2.PO_No))
              return;

            this.matSelectPO.push(data2);
            this.clone = this.matSelectPO.slice();
            this.CompleteClonePO = this.clone.slice();

          }
        });
      });
    });


    // this.part_controller.getPartList().then(data => {
    //   this.partsList = data;
    // })
    this.customer_controller.getCustomerList().then(data => {
      this.matSelectCustomer = data;
      this.cloneCustomer = this.matSelectCustomer.slice();

    });

    this.setForm();
  }
  existingName = false
  async nameValidation() {
    const name =  this.addForm.get('name').value.trim();
    if (name.includes('/') || name.includes('@') || name.includes('[')  || name.includes(']')  || name.includes('.')  || name.includes('#')  || name.includes('$') || name.includes('*') ) {
      this.existingName = true
    }else{
      var snapshot = await this.db.database.ref('PackingList/'+name).once('value');
      if(snapshot.exists())
        this.existingName = true;
      else
        this.existingName = false;
    }
  }

  setForm(){
    const customerControl = new FormControl();
    const customerfilterControl = new FormControl();

    customerfilterControl.valueChanges.subscribe(() => {
      this.findCustomer(customerfilterControl);
    });
    customerControl.valueChanges.subscribe(() => {
      if (customerControl.value) {
        this.searchCustomer = customerControl.value;
        this.addForm.get('destination').setValue(this.cloneCustomer.find(c=>c.Customer_Name === this.searchCustomer).Address);

        const parts = this.addForm.getRawValue().packingLists;
        parts.forEach((element,index) => {
          this.removeParts(index);
        });
        this.matSelectPO = cloneDeep(this.CompleteClonePO)

        this.matSelectPO.forEach(data2 => {

          data2.PO_Part_List = data2.PO_Part_List.filter(p=> p.CustomerName.includes(this.searchCustomer));
        });

        this.matSelectPO = this.matSelectPO.filter(m=>m.PO_Part_List.length > 0);

        this.clone = this.matSelectPO.slice();
      }
    });


    this.addForm = this.fb.group({
      name: this.temporaryPackingNum,
      shipdate: '',
      customerControl: customerControl,
      customerfilterControl: customerfilterControl,
      destination: '',
      packingLists: this.fb.array([]) ,
      currency: '',
    });
  }

  findCustomer(cus){
    if (!this.cloneCustomer) { return; }
    const search = cus.value;
    this.matSelectCustomer = this.cloneCustomer.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));

  }
  updatePart(num, index) {
    this.poNo = num;
    if (this.poNo == '') {
      this.matSelectPO = [];
    }
    this.matSelectPart[index] = null;
    this.po_controller.search_PO_withStatusNotCompleted().then(data => {
      data.forEach(data2 => {
        if (this.poNo !== '') {
          if (this.poNo.match(data2.PO_No) && this.poNo.length === data2.PO_No.length) {
            const data ={
              parts: data2.PO_Part_List,
              PONumber: data2.PO_No,
            }
            this.matSelectPart[index] = data;

          }
        }
      });
    });
  }
  choosingPart= [];

  selectPart(j,i) {
    const samePart = this.choosingPart.find(c=> c===  this.matSelectPart[i].parts[j].ID );
    if(samePart){
      this.toast.warning("Same part number and PO, please choose others", "Warning");
      return;
    }
    const result = parseFloat(this.matSelectPart[i].parts[j].PO_Part_Qty) - parseFloat(this.matSelectPart[i].parts[j].POQuantity) ;
    if(result < 0){
      if( this.matSelectPart[i].parts[j].InStock + result  < 0){
        this.toast.warning("Currently the stock of the selected pack is not enough.", "Warning");
        return;
      }else{
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.height = 'auto';
        dialogConfig.width = '80%';
        const position = {
          top: '5%'
        };
        dialogConfig.position = position;
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          partNo:this.matSelectPart[i].parts[j].PO_Part_No,
          total: result *-1,
        }
        this.dialog.open(SelectPOStockComponent, dialogConfig).afterClosed().subscribe(result => {
          if(result){
            this.matSelectPart[i].parts[j].Stocks = result;
            this.selectedParts[i] = (this.matSelectPart[i].parts[j]);

            const info = this.matSelectPart[i].parts[j].ID;

            var arrayControl = this.addForm.get('packingLists') as FormArray;
            arrayControl.at(i).get('poControl').disable({ onlySelf: true });

            this.matSelectPart[i] = null;
            this.choosingPart.push(info);
          }
          else{
            return;
          }
        });
      }
    }else{
      this.selectedParts[i] = (this.matSelectPart[i].parts[j]);
      const info = this.matSelectPart[i].parts[j].ID;
      var arrayControl = this.addForm.get('packingLists') as FormArray;
      arrayControl.at(i).get('poControl').disable({ onlySelf: true });

      this.matSelectPart[i] = null;
      this.choosingPart.push(info);
    }


  }

  packingLists(): FormArray {
    return this.addForm.get('packingLists') as FormArray;
  }

  newPOs(): FormGroup {
    const pofilterControl = new FormControl();
    const poControl = new FormControl();

    pofilterControl.valueChanges.subscribe(() => {
      this.findPO(pofilterControl);
      if (this.clone) {
        this.check = true;
      }

    });
    poControl.valueChanges.subscribe(() => {
      if (poControl.value) {
        this.searchPO = poControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partNumber: '',
      quantity: '',
      dateControl: new Date(),
      poControl,
      pofilterControl,
      poNo: '',
    });
  }

  findPO(po) {
    if (!this.clone) { return; }
    const search = po.value;
    this.matSelectPO = this.clone.filter(p => p.PO_No.toLowerCase().includes(search.toLowerCase()));
  }

  addPO() {
    this.selectedParts.push(null);
    this.matSelectPart.push(null);
    this.packingLists().push(this.newPOs());
  }

  removeParts(i: number) {
    this.packingLists().removeAt(i);
    this.selectedParts.splice(i, 1);
    this.choosingPart.splice(i, 1);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if(this.existingName){
      this.toast.error('Same Packing List name already been existed', 'Please change');
      return;
    }
    const addPOModel = {
      PackingListName : this.addForm.get('name').value.trim(),
      ShipDate: this.addForm.get('shipdate').value,
      Destination: this.addForm.get('destination').value,
      customer: this.matSelectCustomer.find(s=>s.Customer_Name === this.addForm.get('customerControl').value).Customer_ID,
      parts: [],
      currency: this.addForm.get('currency').value,
    };
    const parts = this.addForm.getRawValue().packingLists;
    parts.forEach((element, index) => {

      if (!this.selectedParts[index]){
        this.toast.error('PO information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }
      const info = {
        poNumber: element.poControl,
        partNumber: this.selectedParts[index].PO_Part_No,
        poQuantity: this.selectedParts[index].POQuantity,
        productionQuantity: this.selectedParts[index].PO_Part_Qty,
        remainingQuantity: (this.selectedParts[index].PO_Part_Qty - this.selectedParts[index].POQuantity).toFixed(2),
        stocks: this.selectedParts[index].Stocks
        // DeliveryOrder: element.DeliveryOrder,
        // Price: element.Price,
      };
      if (!info.poNumber || !info.partNumber
        /*|| !info.DeliveryOrder || !info.Price*/) {
          this.toast.error('PO information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }
      addPOModel.parts.push(info);
    });

    if (addPOModel.parts.length === 0){
      this.toast.error('Please add at least one PO', 'Add PO')
      return;
    }
    if (!addPOModel.PackingListName){
      this.toast.error('Please fill in the Packing List number', 'Please fill in')
      return;
    }
    if (!addPOModel.Destination){
      this.toast.error('Please select a customer', 'Please fill in')
      return;
    }
    if (!addPOModel.currency){
      this.toast.error('Please select a currency', 'Please fill in')
      return;
    }
    let sameRowInvoice = new Invoice;
    this.packing_controller.add_or_update_PackingList(addPOModel, this.email, false, sameRowInvoice);
    this.dialogRef.close(addPOModel);
  }

  display(value){
    if(value> 0)
      return "+"+ value;
    return value;
  }

  view(part){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.data = part.Stocks
    this.dialog.open(InfoComponent, dialogConfig);
  }


}
