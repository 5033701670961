import { AccountSettingsComponent } from './../../../account-settings/account-settings.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-top-nav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {

  hide = false;
  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,

  ) {
    this.angularFireAuth.authState.subscribe(async authState =>{
      if(authState === null){
      } else{
        if(authState.email.includes('operator')){
          this.hide = true;
        }
      }
    })
  }

  ngOnInit() {

  }

  logout() {
    this.angularFireAuth.auth.signOut().then(r=>{
      this.router.navigate(['/login']);

    });

  }
  accSettings() {
    this.dialog.open(AccountSettingsComponent,{
      height:'auto',
      width:'auto',
      disableClose:true
    })
  }

}
