import { PurchaseOrderRaw } from './../../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ScanOutsourceComponent } from '../../scan-outsource/scan-outsource.component';

@Component({
  selector: 'app-detail-outsource',
  templateUrl: './detail-outsource.component.html',
  styleUrls: ['./detail-outsource.component.css']
})
export class DetailOutsourceComponent implements OnInit {
    po = new PurchaseOrderRaw();
    recFlag = false;
    checkFlag = false;
    flag1 = false;
    flag2 = false;
    editFlag = false;
    TDOorPO: any = 'Purchase Order';
    PartorPO: any = 'PO';
    rawMatorOutsoure: any = 'Raw Material';
    raworOutsoure: any = 'Raw Materials Orders';

    ngOnInit() {}

    constructor(
      public dialogRef: MatDialogRef<DetailOutsourceComponent>,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data) {
        this.po = data;

        if(this.po.Type == 'Outsource'){
          this.TDOorPO = "TDO";
          this.rawMatorOutsoure = "Outsource Name";
          this.PartorPO = "Part";
          this.raworOutsoure = "TDO";
          this.editFlag = true;
        }

        let result1 = this.po.PO_RawMaterials.find(e=>e.ReceivedDetailsList.length>0);
        let result2 = this.po.PO_RawMaterials.find(e=>e.PoInRawMaterialList.length>0);
        if(result1)
          this.flag1 = true;
        if(result2)
          this.flag2 = true;
      }

    close(): void {
      this.dialogRef.close();
    }
    integer(q){
      return parseFloat(q);
    }
    showRec(){
      this.recFlag = !this.recFlag;
    }
    showCheck(){
      this.checkFlag = !this.checkFlag

    }
    openImage(url) {
      window.open(url, '_blank');
    }

    edit(){
      const dialogRefaddPart = this.dialog.open(ScanOutsourceComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: this.po
      });
      dialogRefaddPart.afterClosed().subscribe(result => {
        if(result){
          this.dialogRef.close();
        }
      });
    }
  }
