import { PartServices } from './../../../Services/Utilities/part.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserInfoService } from './../../../Services/Utilities/user-info.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AccountSettingsComponent } from '../../account-settings/account-settings.component';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  today = Date.now();
  disabledNavigation = false;
  role:string;
  acccessRight:any[]=[];
  partDB_controllers: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  done=false;
  expand = false;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private db: AngularFireDatabase,
    private dialog:MatDialog,
    private toast:ToastrService,
    private storage: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private userinfoSrv:UserInfoService,
    private partSrv:PartServices,


  ) {

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    const service = this.angularFireAuth.authState.subscribe(async authState =>{
      if(authState === null){
        this.toast.warning('Session expired please login again');
        this.router.navigate(['/login']);
        service.unsubscribe();
      }
      else{
        const snapshot = await this.db.database.ref('Operators').child(authState.uid).once('value');

        if (snapshot.exists()) {
          this.disabledNavigation = true;
          this.done=true;
        }
        else{
          this.spinner.show();
          this.partDB_controllers.getPartList().then(part=>{
            this.partSrv.SetPart(part);
          })
          .finally(()=>{
            this.done=true;
            this.spinner.hide();
          })

          const userSnapshot = await this.db.database.ref('User').child(authState.uid).once('value');

          if(userSnapshot.exists()){
            const role = userSnapshot.child('Role').val();
            this.userinfoSrv.SetRole(role);
            const accessRights = [];

            if(role !== 'Adminstrator' && role !== 'Director'){
              userSnapshot.child('Access').forEach(e=>{
                const info={
                  module: e.key,
                  right: e.val(),
                }

                accessRights.push(info);
              });

              this.userinfoSrv.setAccessRight(accessRights);

              if(this.router.url === '/home'){
                this.router.navigate(['/home/'+accessRights[0].module.replaceAll(' ', '')])
              }
              else{
                const url = this.router.url.split('/home/')[1];
                const result = this.acccessRight.find(a=>a.module.replaceAll(' ','') === url);

                if(!result){
                  this.toast.warning('You have no right to access this module','Warning')
                  this.angularFireAuth.auth.signOut().then(e=>{
                    this.router.navigate(['/login']);
                  });

                }
              }
            }
            else if(role === 'Adminstrator' || role === 'Director'){
              if(this.router.url === '/home'){
                this.router.navigate(['/home/POManagement'])
              }
            }
          }
        }

        service.unsubscribe();
      }
    });

    this.userinfoSrv.currentRole.subscribe(role=>this.role = role);
    this.userinfoSrv.currentAccessRights.subscribe(access=>this.acccessRight = access);
  }

  checkRight(module){
    return this.userinfoSrv.checkRight(this.role,module, this.acccessRight);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  logout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Log out? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.angularFireAuth.auth.signOut();

        setTimeout(() => {
          this.userinfoSrv.SetRole('')
          this.userinfoSrv.setAccessRight([]);
          this.router.navigate(['/login']);
        }, 1000);
      }
    })
  }

  sidebar(){
    this.expand = !this.expand;
  }

  accSettings() {
    this.dialog.open(AccountSettingsComponent,{
      height:'70%',
      width:'70%',
      disableClose:true
    })
  }

  getActive(value){
    if(value === this.router.url)
      return true

    return false
  }
}

