import { ManageCrushingTypeComponent } from './manage-crushing-type/manage-crushing-type.component';
import { ViewCrushingMaterialComponent } from './view-crushing-material/view-crushing-material.component';
import { CrushingMaterialDB_controller } from './../../../Services/DB_Controller/CrushingMaterialDB_Controller';
import { CrushingMaterial } from './../../../Services/Object_Classes/CrushingMaterial/CrushingMaterial';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AddCrushingMaterialComponent } from './add-crushing-material/add-crushing-material.component';
import { QRCODEComponent } from './qrcode/qrcode.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';

@Component({
  selector: 'app-crushing-material',
  templateUrl: './crushing-material.component.html',
  styleUrls: ['./crushing-material.component.css']
})
export class CrushingMaterialComponent implements OnInit {

  //Pagination Raw Materials
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  RawList: CrushingMaterial[] = [];
  CloneRawList: CrushingMaterial[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;
  CrushingMaterialDB_controllers: CrushingMaterialDB_controller = new CrushingMaterialDB_controller(this.db, this.firestore);
  email: string;

  @Output() someEvent = new EventEmitter<number>();

  constructor(public dialog: MatDialog,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,) {
      this.angularFireAuth.authState.subscribe(auth => {
        this.email = auth.email;
      });
      this.setup();

    }

  ngOnInit() {
  }
  setup() {
    this.spinner.show();
    this.initCrushingMaterial();
  }

  callParent() {
    this.someEvent.next(this.RawList.length);
  }
  refresh(){
    this.spinner.show();
    this.initCrushingMaterial();


  }

  initCrushingMaterial(){
    this.CrushingMaterialDB_controllers.getCurshingMaterialList().then(data => {
      this.RawList = data;
      this.length = this.RawList.length;
      this.CloneRawList = this.RawList.slice();
      this.limitList();
      this.spinner.hide();
      this.callParent();
    });
  }

  DynamicSearch(rawName: string): void {
    this.search = rawName;
    this.CloneRawList = this.RawList.filter(u =>
      String(u.RawMaterial).toLowerCase().includes(this.search.toLowerCase()));
    this.length = this.CloneRawList.length;
    this.sortRawList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
  }
  DS() {
    this.CloneRawList = this.RawList.filter(u =>
      String(u.RawMaterial).toLowerCase().includes(this.search.toLowerCase()));
    this.length = this.CloneRawList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.CloneRawList = this.RawList.slice();
    }
    this.sortRawList();
    this.limitList();
  }
  limitList() {
    this.CloneRawList = this.CloneRawList.slice(this.offset, (this.offset + this.pageSize));
  }
  sortRawList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.CloneRawList = this.CloneRawList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'batch': return this.compare(a.MaterialBatch, b.MaterialBatch, isAsc);
        case 'pigment': return this.compare(a.Pigment, b.Pigment, isAsc);
        case 'stock': return this.compare(a.Stock, b.Stock, isAsc);
        case 'updatedBy': return this.compare(a._Updated_By, b._Updated_By, isAsc);
        case 'crush': return this.compare(a.CrushCode, b.CrushCode, isAsc);
        case 'lastUpdate': return this.compareDate(a._Last_Updated, b._Last_Updated, isAsc);
        case 'name': return this.compare(a.RawMaterial, b.RawMaterial, isAsc);
        default: return 0;
      }
    });
  }
  sortData(sort: Sort) {
    this.sortedu = sort;
    this.CloneRawList = this.RawList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneRawList = this.RawList.slice();
      this.limitList();
      return;
    }

    this.CloneRawList = this.CloneRawList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'batch': return this.compare(a.MaterialBatch, b.MaterialBatch, isAsc);
        case 'pigment': return this.compare(a.Pigment, b.Pigment, isAsc);
        case 'stock': return this.compare(a.Stock, b.Stock, isAsc);
        case 'crush': return this.compare(a.CrushCode, b.CrushCode, isAsc);
        case 'updatedBy': return this.compare(a._Updated_By, b._Updated_By, isAsc);
        case 'lastUpdate': return this.compareDate(a._Last_Updated, b._Last_Updated, isAsc);
        case 'name': return this.compare(a.RawMaterial, b.RawMaterial, isAsc);
      default: return 0;
      }
    });
    this.limitList();
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  AddCrushingMaterial(){
    const dialogRefaddPart = this.dialog.open(AddCrushingMaterialComponent, {
      width: '50%',
      height: 'auto',
      position:{
        top:'5%'
      },
      disableClose: true,
    });
    var newCrushing = new CrushingMaterial();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newCrushing = result;
        if (!newCrushing.Stock){
          newCrushing.Stock = 0;
        }
        if (!newCrushing.MaterialBatch){
          newCrushing.MaterialBatch = '-';
        }
        if (!newCrushing.Pigment){
          newCrushing.Pigment = '-';
        }
        this.CrushingMaterialDB_controllers.addCrushingMaterial(newCrushing, this.email);
        this.setup();

      }
    });
  }

  viewDetail(raw){
    this.dialog.open(ViewCrushingMaterialComponent, {
      width: '50%',
      height: 'auto',
      disableClose: true,
      data: raw
    }).afterClosed().subscribe(r=>{
      if(r){
        this.CrushingMaterialDB_controllers.updateCrushingMaterial(r,this.email);
        this.setup();
      }
    });
  }

  deleteCrushing(raw : CrushingMaterial){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this crushing material? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CrushingMaterialDB_controllers.deleteCrushingMaterial(raw.ID);
        this.setup();
      }
    })
  }

  generateQR(raw:CrushingMaterial){
    this.dialog.open(QRCODEComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: raw
    })
  }


  ManageCrushCode(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(ManageCrushingTypeComponent, dialogConfig);
  }
  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const rawlist = this.RawList.sort((a,b)=>{
      return (a.CrushCode < b.CrushCode ? -1 : 1)* (true ? 1 : -1);
    }
      );
    for (const raw of rawlist) {
      const info ={
        "Material Name":raw.RawMaterial || "-",
        "Material Batch": raw.MaterialBatch || "-",
        "Pigment":raw.Pigment || 0,
        "Code":raw.CrushCode || 0,
        "Stock":raw.Stock || 0,
        "Created Date":raw._Created_Date || "-",
        "Updated Date":raw._Last_Updated|| "-",
        "Updated By":raw._Updated_By|| "-",
      }
      exportInformation.push(info)
    }

    this.excelHelper.exportAsExcelFile(exportInformation, 'OtherMaterial'+new Date().getTime());
    this.spinner.hide();

  }

}
