import { PartTracker } from './../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { v4 as uuidv4 } from 'uuid';
import { MatDatepickerInputEvent } from '@angular/material';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { NgxSpinnerService } from 'ngx-spinner';

const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-detail-po-page',
  templateUrl: './detail-po-page.component.html',
  styleUrls: ['./detail-po-page.component.css']
})
export class DetailPOPageComponent implements OnInit {

  po: PurchaseOrder = new PurchaseOrder();
  poTemp: PurchaseOrder = new PurchaseOrder();
  PODB_controller: PODB_controller = new PODB_controller(this.db);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);
  poTempPart: PartTracker[] = [];
  matSelectCustomer: Customer[] = [];
  customerlist: Customer[] = [];
  rawTypes = [];
  email:string;
  partlength;
  changeaAllStart: Date = new Date();
  changeaAllEnd: Date = new Date();
  Staff: Staff[] = [];
  CloneStaff: Staff[] = [];
  staffName = '';
  assignTo = '';

  public date: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;


  constructor(
    public dialogRef: MatDialogRef<DetailPOPageComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private spinner : NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.po = cloneDeep(data);
    this.poTemp = cloneDeep(data);
    this.poTempPart = this.poTemp.PO_Part_List;
    this.partlength = this.po.PO_Part_List.length;
  }

  ngOnInit(): void {
    this.StaffDB_controller.getStaffList().then(data => {
      this.Staff = data;
      this.CloneStaff = this.Staff.slice();
      this.spinner.hide();
    });
  }

  close(): void {
    this.dialogRef.close();
  }
  
  update(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update this with this SO? ' + this.po.PO_No;

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        for (const part of this.po.PO_Part_List) {
          var snapshot = await this.db.database.ref('Part/' + part.PO_Part_ID).once('value');
          var oldPart = this.poTempPart.find(a=>a.ID == part.ID);
          var scheduleId = uuidv4();
          
          if(snapshot.exists()){
            await this.db.database.ref('Part/' + part.PO_Part_ID + '/Part No').set(part.PO_Part_No);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/PO Quantity').set(part.POQuantity);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/Start Date').set(part.StartDate.toISOString());
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/End Date').set(part.EndDate.toISOString());
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/Part Name').set(part.PO_Part_Name);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/Part No').set(part.PO_Part_ID);
            
            if(part.AssignTo != null && part.AssignTo != "" && part.AssignTo != "-" && part.AssignTo != oldPart.AssignTo){
              await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/Assign To').set(part.AssignTo);
            }
          }
          else{
            var partId = uuidv4();
            await this.db.database.ref('Part/' + partId + '/Part No').set(part.PO_Part_No);
            await this.db.database.ref('Part/' + partId + '/Part Name').set(part.PO_Part_Name);
            await this.db.database.ref('Part/' + partId + '/Stock Quantity').set(0);

            var JO = this.po.PO_No + '-' + (this.partlength + 1).toString();
            part.ID = JO;
            this.partlength = this.partlength + 1;
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/PO Quantity').set(part.POQuantity);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Start Date').set(part.StartDate.toISOString());
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO+ '/End Date').set(part.EndDate.toISOString());
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Part Name').set(part.PO_Part_Name);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Part No').set(partId);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Part Quantity').set(0);
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/PO Quantity').set(part.POQuantity);
            //await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Status').set("Planned Material");
            //await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Status').set("Drawing Review");
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ JO + '/Assign To').set(part.AssignTo);
          }

          if(part.AssignTo != null && part.AssignTo != "" && part.AssignTo != "-"){
            let updates2 = {};
            updates2['_SO_No'] = this.po.PO_No;
            updates2['_JO_No'] = part.ID;
            updates2['_Status'] = "Pending";

            if(part.AssignTo != oldPart.AssignTo){
              var oldstaff = this.Staff.find(a=>a.StaffID == oldPart.AssignTo);

              if(oldstaff){
                var oldschedule = oldstaff.Schedule_Track.find(a=>a.JO_No == part.ID);
                await this.db.database.ref('User/'+oldPart.AssignTo+'/Schedule/'+oldschedule.ID).set(null);
                await this.db.database.ref('User/'+part.AssignTo+'/Schedule/'+scheduleId+'/').update(updates2);
              }else{
                await this.db.database.ref('User/'+part.AssignTo+'/Schedule/'+scheduleId+'/').update(updates2);
              }
            }

            let updates3 = {};
            const todayDate = this.getTodayDate();
            let childSnapshot = await this.db.database.ref('Schedule/'+todayDate+'/'+part.AssignTo).once('value');

            if (childSnapshot.exists()) {
              let quantity = parseInt(childSnapshot.child('new').val());
              updates3[todayDate+'/'+part.AssignTo+'/new'] = (quantity + 1);
              await this.db.database.ref('Schedule/').update(updates3);
            }
            else{
              updates3[todayDate+'/'+part.AssignTo+'/new'] = 1;
              updates3[todayDate+'/'+part.AssignTo+'/bf'] = 0;
              updates3[todayDate+'/'+part.AssignTo+'/cf'] = 0;
              updates3[todayDate+'/'+part.AssignTo+'/output'] = 0;
              await this.db.database.ref('Schedule/').update(updates3);
            }
          }

          if(this.po.Status == 'Complete'){
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/Status').set('Complete');
          }
          else if(this.po.Status == 'Cancelled'){
            await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/Part List/'+ part.ID + '/Status').set('Cancelled');
          }
        }

        if(this.po.Status == 'Complete'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/_Status').set('Complete');
        }
        else if(this.po.Status == 'Cancelled'){
          await this.db.database.ref('Purchase Order/' + this.po.PO_No + '/_Status').set('Cancelled');
        }

        const update ={}
        update[this.po.PO_No + '/_Updated_Date'] = new Date();
        update[this.po.PO_No + '/_Updated_By'] = this.email;
        this.db.database.ref('Purchase Order/').update(update);
        this.dialogRef.close();
      }
    });
  }

  addParts() {
    this.po.PO_Part_List.push(new PartTracker());
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  deleteSchedule(part:PartTracker){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you want to delete this JO?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.PODB_controller.delete_ScheduleFromPO(this.po.PO_No, part.ID);
        //this.dialogRef.close(true);
        var index = this.po.PO_Part_List.indexOf(part);
        this.po.PO_Part_List.splice(index, 1);
      }
    })
  }

  bulkStartDate(bulkStartDate : MatDatepickerInputEvent<Date>) {
    for (const part of this.po.PO_Part_List){
      part.StartDate = bulkStartDate.value;
    }
  }

  bulkEndDate(bulkEndDate : MatDatepickerInputEvent<Date>) {
    for (const part of this.po.PO_Part_List){
      part.EndDate = bulkEndDate.value;
    }
  }

  getTodayDate(): string {
    const today = new Date();
  
    // Extract year, month, and day components
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = today.getDate().toString().padStart(2, '0');
  
    // Concatenate components in yyyyMMdd format
    const formattedDate = `${year}${month}${day}`;
  
    return formattedDate;
  }
}

