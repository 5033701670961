import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-view-raw-materials',
  templateUrl: './view-raw-materials.component.html',
  styleUrls: ['./view-raw-materials.component.css']
})
export class ViewRawMaterialsComponent implements OnInit {

  RawMaterial: RawMaterialInfo[] = [];
  partInfo : any;
  partInfo2 : any;
  partInfo3 : any;
  cartonNeeded : any;
  polyBagNeeded : any;
  innerBoxNeeded : any;
  polyCheck = false;
  innerCheck = false;
  multiple: number;


  constructor(
    private dialogRef: MatDialogRef<ViewRawMaterialsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    this.multiple = data.multiple
    this.partInfo = data.partInfo;
    this.partInfo2 = data.partInfo2;
    this.partInfo3 = data.partInfo3;
    this.RawMaterial = cloneDeep(data.materials);

    var total;
    this.RawMaterial.forEach((element,index) => {
      if(element.Raw_Type === 'Raw Material'){

        let cal1 = 0;
        let cal2 = 0;
        let cal3 = 0;

        cal1 += ((((parseFloat(this.partInfo.Part_Runner_Weight) / parseFloat(this.partInfo.Number_Cavity)) + parseFloat(this.partInfo.Part_Weight)) * (parseFloat(data.quantity)) / 1000));

        if(this.partInfo2)
          cal2 += ((((parseFloat(this.partInfo2.Part_Runner_Weight) / parseFloat(this.partInfo2.Number_Cavity)) + parseFloat(this.partInfo2.Part_Weight)) * (parseFloat(data.quantity)) / 1000));

        if(this.partInfo3)
          cal3 += ((((parseFloat(this.partInfo3.Part_Runner_Weight) / parseFloat(this.partInfo3.Number_Cavity)) + parseFloat(this.partInfo3.Part_Weight)) * (parseFloat(data.quantity)) / 1000));

        element.quantityNeeded = (cal1 + cal2 + cal3).toFixed(2);

        total = element.quantityNeeded;
      }
    });
    this.RawMaterial.forEach(element=>{
      if(element.Raw_Type === 'Material Batch')
        element.quantityNeeded = ((parseFloat(total) * parseFloat(element.Mat_Ratio) / 100)).toFixed(2)
      else if(element.Raw_Type === 'Pigment')
        element.quantityNeeded = ((parseFloat(total) / 25 )).toFixed(2)

    })




   this.cartonNeeded = Math.ceil((parseFloat(data.quantity) / parseFloat(this.partInfo.Box_Quantity) )) * this.multiple
   this.polyBagNeeded = Math.ceil((parseFloat(data.quantity) / parseFloat(this.partInfo.Polybag_Quantity))) * this.multiple
   this.innerBoxNeeded = Math.ceil((parseFloat(data.quantity) / parseFloat(this.partInfo.Quantity_InnerBox))) * this.multiple

   if(!isFinite(this.cartonNeeded)){
    this.cartonNeeded = 0;
   }

   if(!isFinite(this.polyBagNeeded)){
    this.polyBagNeeded = 0;
   }

   if(!isFinite(this.innerBoxNeeded)){
    this.innerBoxNeeded = 0;
   }

  }

  ngOnInit(){

  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm(){
    const info={
      Material :this.RawMaterial,
      polyCheck :this.polyCheck,
      innerCheck :this.innerCheck,
      cartonNeeded :this.cartonNeeded ,
      polyBagNeeded :this.polyBagNeeded ,
      innerBoxNeeded :this.innerBoxNeeded ,

    }
    this.dialogRef.close(info);

  }

  parFloat(value){
    return parseFloat(value);
  }

  iNaN(value){
    return isNaN(value);
  }

}
