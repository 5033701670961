import { BoxInfo, PointList, RejectedInfo, SampleList, TakenbyPO } from './../Object_Classes/Weighting/Weighting';
import { AngularFireDatabase } from '@angular/fire/database';
import { Weighting, WeightPartList } from '../Object_Classes/Weighting/Weighting';
import moment from 'moment';


export class WeightingDB_controller {
  constructor(private db: AngularFireDatabase) {}

  /************** Weighting ****************/
  async getWeightingList(): Promise<Weighting[]> {
    let WeightList: Weighting[] = [];
    var snapshot = await this.db.database.ref('QC').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        let weight = new Weighting();
        weight.PO_No = childSnapshot.key;

        var part_trackList: WeightPartList[] = [];
        childSnapshot.forEach( (childSnapshot2)=> {
          let weight_track = new WeightPartList;

          weight_track.JO_No = childSnapshot2.key;
          weight_track.Part_ID = childSnapshot2.child("Part No").val();
          weight_track.Part_Name = childSnapshot2.child("Part Name").val();
          weight_track.PO_Qty = childSnapshot2.child("PO Qty").val();
          weight_track.rev = childSnapshot2.child("REV").val();
          weight_track.Inspect_Qty = childSnapshot2.child("Inspect Qty").val();
          weight_track.InspectedBy = childSnapshot2.child("Inspected by").val();
          weight_track.Accept_Qty = childSnapshot2.child("Accepted Qty").val();
          weight_track.Reject_Qty = childSnapshot2.child("Rejected Qty").val();
          weight_track.Weight_Date = new Date(moment(childSnapshot2.child("QC Date").val()).format());
          weight_track.Due_Date = new Date(moment(childSnapshot2.child("Due Date").val()).format());
          weight_track.UpdateBy = childSnapshot2.child("Update_by").val();
          weight_track.InspectionType = childSnapshot2.child("Inspection Type").val();
          weight_track.customerName = childSnapshot2.child("Customer Name").val();
          weight_track.Status = childSnapshot2.child("Status").val();
          this.getPartInfo(weight_track);

          if(childSnapshot2.child("Points") != null){
            var pointList: PointList[] = [];
            childSnapshot2.child("Points").forEach( (childSnapshot3)=> {
              let point_list = new PointList;

              point_list.Unit = childSnapshot3.child("Unit").val();
              point_list.Standard = childSnapshot3.child("Standard").val();
              point_list.Tolerance = childSnapshot3.child("Tolerence").val();
              point_list.Tool = childSnapshot3.child("Tool").val();

              var sampleList: SampleList[] = [];
              let index = 1
              childSnapshot3.child("Samples").forEach( (childSnapshot4)=> {
                let sample_list = new SampleList;

                sample_list.Reading = childSnapshot4.child("Sample " + index.toString()).val();
                sampleList.push(sample_list);
                index++;
              });

              point_list.SampleList = sampleList;
              pointList.push(point_list);
            });

            weight_track.PointList = pointList;
          }

          part_trackList.push(weight_track);
        });

        weight.Weight_Part_List = part_trackList;
        WeightList.push(weight);
      });
    }

    return WeightList;
  }

  async getWeightPartList(): Promise<WeightPartList[]> {
    let WeightList: WeightPartList[] = [];
    var snapshot = await this.db.database.ref('QC').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        //let weight = new Weighting();
        //weight.PO_No = childSnapshot.key;

        //var part_trackList: WeightPartList[] = [];
        childSnapshot.forEach( (childSnapshot2)=> {
          let weight_track = new WeightPartList;

          weight_track.JO_No = childSnapshot2.key;
          weight_track.Part_No = childSnapshot2.child("Part No").val();
          weight_track.Part_Name = childSnapshot2.child("Part Name").val();
          weight_track.PO_Qty = childSnapshot2.child("PO Qty").val();
          weight_track.Inspect_Qty = childSnapshot2.child("Inspect Qty").val();
          weight_track.Accept_Qty = childSnapshot2.child("Accepted Qty").val();
          weight_track.Reject_Qty = childSnapshot2.child("Rejected Qty").val();
          weight_track.Weight_Date = new Date(moment(childSnapshot2.child("QC Date").val()).format());
          weight_track.Due_Date = new Date(moment(childSnapshot2.child("Due Date").val()).format());
          weight_track.UpdateBy = childSnapshot2.child("Update_by").val();
          weight_track.Status = childSnapshot2.child("Status").val();

          WeightList.push(weight_track);
        });

        //weight.Weight_Part_List = part_trackList;
        //WeightList.push(weight);
      });
    }

    return WeightList;
  }

  async getIncompleteWeightingList(): Promise<WeightPartList[]> {
    let WeightList: WeightPartList[] = [];
    var snapshot = await this.db.database.ref('QC').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        childSnapshot.forEach( (childSnapshot2)=> {
          if(childSnapshot2.child("Status").val() == 'Incomplete'){
            let weight_track = new WeightPartList;

            weight_track.SO_No = childSnapshot.key;
            weight_track.JO_No = childSnapshot2.key;
            weight_track.Part_ID = childSnapshot2.child("Part No").val();
            weight_track.Part_Name = childSnapshot2.child("Part Name").val();
            weight_track.PO_Qty = childSnapshot2.child("PO Qty").val();
            weight_track.Inspect_Qty = childSnapshot2.child("Inspect Qty").val();
            weight_track.Accept_Qty = childSnapshot2.child("Accepted Qty").val();
            weight_track.Reject_Qty = childSnapshot2.child("Rejected Qty").val();
            weight_track.Weight_Date = new Date(moment(childSnapshot2.child("QC Date").val()).format());
            weight_track.Due_Date = new Date(moment(childSnapshot2.child("Due Date").val()).format());
            weight_track.UpdateBy = childSnapshot2.child("Update_by").val();
            weight_track.Status = childSnapshot2.child("Status").val();
            this.getPartInfo(weight_track);
            this.getPOInfo(weight_track);

            WeightList.push(weight_track);
          }
        });
      });
    }

    return WeightList;
  }

  async getPOInfo(pt: WeightPartList): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        pt.customerName = childSnapshot.child('_Customer').val();
      })
    }
  }

  async getPointInfo(pt: WeightPartList): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        pt.customerName = childSnapshot.child('_Customer').val();
      })
    }
  }

  async getSampleInfo(pt: WeightPartList): Promise<void> {
    var snapshot = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        pt.customerName = childSnapshot.child('_Customer').val();
      })
    }
  }

  async updateQCWithoutSchedule(_newPO: any, email) {
    let updates1 = {};

    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Inspection Type'] = _newPO.inspectType;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Customer Name'] = _newPO.customerName;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Drawing No'] = _newPO.drawingNo;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/REV'] = _newPO.rev;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Inspected by'] = _newPO.inspectBy;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Status'] = "Complete";
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Inspect Qty'] = parseInt(_newPO.lotSize);
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Accepted Qty'] = parseInt(_newPO.lotSize);
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Rejected Qty'] = 0;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/QC Date'] = new Date();

    await _newPO.parts.forEach(async (data, index) => {
      var point = 'Point ' + (parseInt(index)+1).toString();

      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Standard'] = data.standard;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Tolerence'] = data.tolerence;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Tool'] = data.tool;

      await data.samples.forEach(async (data2, index) => {
        var sample = 'Sample ' + (parseInt(index)+1).toString();
        updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Samples/' + sample] = data2.reading;
      });
    });

    await this.db.database.ref('QC/').update(updates1);
    await this.db.database.ref('Purchase Order/' + _newPO.soNumber + '/Part List/' + _newPO.joNumber + '/Status').set("Complete");
    await this.db.database.ref('Purchase Order/' + _newPO.soNumber + '/_Status').set("Complete");

    var snapshot = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          if(childSnapshot2.child('Status').val() != 'Complete'){
            this.db.database.ref('Purchase Order/' + _newPO.soNumber + '/_Status').set("Incomplete");
            return;
          }
        });
      });
    }
  }

  async updateRejectedQCWithoutSchedule(_newPO: any, _newQC: any, email) {
    let updates1 = {};
    
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Inspection Type'] = _newPO.inspectType;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Customer Name'] = _newPO.customerName;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Drawing No'] = _newPO.drawingNo;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/REV'] = _newPO.rev;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Inspected by'] = _newPO.inspectBy;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Status'] = "Complete";
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Inspect Qty'] = parseInt(_newPO.lotSize);
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Accepted Qty'] = (parseInt(_newPO.lotSize) - _newQC.parts.length);
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Rejected Qty'] = _newQC.parts.length || 0;
    updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/QC Date'] = new Date();

    await _newPO.parts.forEach(async (data, index) => {
      var point = 'Point ' + (parseInt(index)+1).toString();

      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Unit'] = data.unit;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Standard'] = data.standard;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Tolerence'] = data.tolerence;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Tool'] = data.tool;

      await data.samples.forEach(async (data2, index) => {
        var sample = 'Sample ' + (parseInt(index)+1).toString();
        updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Points/' + point + '/Samples/' + sample] = data2.reading;
      });
    });

    await _newQC.parts.forEach(async (data, index) => {
      var point = 'Reason ' + (parseInt(index)+1).toString();

      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Rejection List/' + point + '/Reason'] = data.Reason;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Rejection List/' + point + '/Quantity'] = data.Quantity;
      updates1[_newPO.soNumber + '/' + _newPO.joNumber + '/Rejection List/' + point + '/Remarks'] = data.Remarks || '';
    });

    await this.db.database.ref('QC/').update(updates1);
    await this.db.database.ref('Purchase Order/' + _newPO.soNumber + '/Part List/' + _newPO.joNumber + '/Status').set("Complete");
    await this.db.database.ref('Purchase Order/' + _newPO.soNumber + '/_Status').set("Complete");

    let updates2 = {};
    var JO = _newPO.joNumber + '-R';
    updates2[_newPO.soNumber + '/Part List/' + JO + '/Part No'] = _newPO.partNo;
    updates2[_newPO.soNumber + '/Part List/' + JO + '/Part Name'] = _newPO.partName;
    updates2[_newPO.soNumber + '/Part List/' + JO + '/Part Quantity'] = 0;
    updates2[_newPO.soNumber + '/Part List/' + JO + '/PO Quantity'] = _newQC.parts.length;
    updates2[_newPO.soNumber + '/Part List/' + JO + '/Status'] = "Incomplete";
    updates2[_newPO.soNumber + '/Part List/' + JO + '/Start Date'] = _newQC.issuedDate;
    updates2[_newPO.soNumber + '/Part List/' + JO + '/End Date'] = _newQC.issuedDate;

    await this.db.database.ref('Purchase Order/').update(updates2);

    var snapshot = await this.db.database.ref('Purchase Order').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          if(childSnapshot2.child('Status').val() != 'Complete'){
            this.db.database.ref('Purchase Order/' + _newPO.soNumber + '/_Status').set("Incomplete");
            return;
          }
        });
      });
    }
  }

  delete_Weight(PO_No: string, Part_No: string) {
    this.db.database.ref('/Weighting/' + PO_No + '/' + Part_No).set(null);
  }

  async getPartInfo(pt: WeightPartList): Promise<void> {
    var snapshot = await this.db.database.ref('Part').child(pt.Part_ID).once('value');
    if (snapshot.exists()) {
      pt.Part_No = snapshot.child('Part No').val();
    }
  }
}
