import { SupplierDB_controller } from '../../../Services/DB_Controller/SupplierDB_controller';
import { RawMaterialInfo } from '../../../Services/Object_Classes/RawMaterial/RawMaterial';
import { RawDB_controller } from '../../../Services/DB_Controller/RawDB_controller';
import { RawMaterialOrder } from '../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { v4 as uuidv4 } from 'uuid';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';

@Component({
  selector: 'app-scan-outsource',
  templateUrl: './scan-outsource.component.html',
  styleUrls: ['./scan-outsource.component.css']
})
export class ScanOutsourceComponent implements OnInit {
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;

  partDB_controllers: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  RawDbController: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  supplierDbController: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);
  po_controller: PODB_controller = new PODB_controller(this.db);

  idLists: any = [];
  rawTypes: any = [];
  partList: any = [];
  partUomList: any = [];
  outsourcelist: RawMaterialInfo[] = [];
  outsourceslist: any = [];
  DetailsOutsources: RawMaterialInfo[] = [];
  matOutsourcesRaw: any = [];
  cloneOutsourcesRaw: any = [];
  matSelectPO: PartTracker[] = [];
  cloneSelectPO: PartTracker[] = [];

  addForm: FormGroup;
  isScanned = false;
  supplier: Supplier[] = [];
  cloneSupplier: Supplier[] = [];
  po = new PurchaseOrderRaw();

  minDate :Date;
  requiredDate :Date = new Date();
  email: string;
  scanning: any = '';
  Type: any;
  Rule: any = 0;
  update: any = false;

  constructor(
    private dialogRef: MatDialogRef<ScanOutsourceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private storage: AngularFireStorage,
    private dateFormat: DateFormatService
  ) {
    this.minDate = new Date();
    const supplierFilterControl = new FormControl();

    this.addForm = this.fb.group({
      supplierFilterControl,
      outsourceSupplierControl: "",
      outsourceControl: "",
      receivedDate: new Date(),
      outsources: this.fb.array([]),
    });

    supplierFilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplierFilterControl);
    });

    this.angularFireAuth.authState.subscribe(auth=>{this.email = auth.email;});

    if(data){
      this.update = true;
      this.po = data;
      this.addForm.controls.outsourceSupplierControl.setValue(this.po.Supplier);
      this.addForm.controls.outsourceControl.setValue(this.po.Outsource_Type);
      this.addForm.controls.receivedDate.setValue(this.po.Pr_Date);

      this.po.PO_RawMaterials.forEach(a => {
        this.outsources().push(this.updateOutsources(a.JO_No, a.Part_ID, a.Part_Uom, a.Part_Name, a.PO_RawMaterial_Qty, a.Delivery_Date, a.Remark, a.PO_RawMaterial_Name));
        this.outsourcelist.push(null);
        this.outsourceslist.push(a.PO_RawMaterial_Name);
        this.idLists.push(a.PO_Order_ID);
      });
    }
  }

  ngOnInit() {
    this.spinner.show();

    this.po_controller.getJOList().then(data => {
      this.matSelectPO = data;
      this.cloneSelectPO = this.matSelectPO.slice();
      this.spinner.hide();
    });

    this.supplierDbController.getOutsourceSupplierList().then(data => {
      this.supplier = data;
      this.cloneSupplier = this.supplier.slice();
      this.spinner.hide();
    });

    this.RawDbController.getOutsourceTypeList().then(data=>{
      this.rawTypes = data;
      this.spinner.hide();
    });

    this.RawDbController.getOutsourceList().then(data => {
      this.DetailsOutsources = data;

      if(this.po.PO_No != null){
        this.matOutsourcesRaw = this.DetailsOutsources.filter(a=>a.Raw_Type == this.po.Outsource_Type);
        this.cloneOutsourcesRaw = this.matOutsourcesRaw.slice();
        this.Type = this.po.Outsource_Type;
        this.Rule = this.rawTypes.find(a=>a.value == this.po.Outsource_Type).rule;

        this.outsourcelist.forEach((data, index)=> {
          this.outsourcelist[index] = this.matOutsourcesRaw.find(p => p.Material_Name == this.outsourceslist[index]);
        });
      }
    });
  }

  outsources(): FormArray {
    return this.addForm.get('outsources') as FormArray;
  }

  newOutsourcesBarcode(joNo: any, partNumber: any, uom: any, name: any, quant: any, prdate: any): FormGroup {
    const outsourceNameControl = new FormControl();
    this.partList.push(partNumber);
    this.partUomList.push(uom);
    const projectFilterControl = new FormControl();
    const outsourceFilterControl = new FormControl();

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    outsourceFilterControl.valueChanges.subscribe(() => {
      this.findOutsourceName(outsourceFilterControl);
    });

    this.scrollToBottom();

    return this.fb.group({
      projectFilterControl,
      outsourceFilterControl,
      project: joNo,
      partName: name,
      outsourceNameControl,
      quantity: quant,
      prDate: prdate,
      remark:"",
    });
  }

  updateOutsources(joNo: any, partNumber: any, uom: any, name: any, quant: any, prdate: any, remark: any, outsourceName: any): FormGroup {
    this.partList.push(partNumber);
    this.partUomList.push(uom);
    const projectFilterControl = new FormControl();
    const outsourceFilterControl = new FormControl();

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    outsourceFilterControl.valueChanges.subscribe(() => {
      this.findOutsourceName(outsourceFilterControl);
    });

    return this.fb.group({
      projectFilterControl,
      outsourceFilterControl,
      project: joNo,
      partName: name,
      outsourceNameControl: outsourceName,
      quantity: quant,
      prDate: prdate,
      remark: remark,
    });
  }

  newOutsources(): FormGroup {
    const projectFilterControl = new FormControl();
    const outsourceFilterControl = new FormControl();

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    outsourceFilterControl.valueChanges.subscribe(() => {
      this.findOutsourceName(outsourceFilterControl);
    });

    this.scrollToBottom();

    return this.fb.group({
      projectFilterControl,
      outsourceFilterControl,
      project: "",
      partName: "",
      outsourceNameControl:"",
      quantity: 0,
      prDate: new Date(),
      remark:"",
    });
  }

  findJO(supplier) {
    if (!this.cloneSelectPO) { return; }
    const search = supplier.value;
    this.matSelectPO = this.cloneSelectPO.filter(p => p.JO_No.toLowerCase().includes(search.toLowerCase()));
  }

  findSupplier(supplier) {
    if (!this.cloneSupplier) { return; }
    const search = supplier.value;
    this.supplier = this.cloneSupplier.filter(p => p.Supplier_Name.toLowerCase().includes(search.toLowerCase()));
  }

  async findOutsourceName(supplier) {
    if (!this.cloneOutsourcesRaw) { return; }
    const search = supplier.value;
    this.matOutsourcesRaw = await this.cloneOutsourcesRaw.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
    this.matOutsourcesRaw.sort((a, b) => a.Material_Name > b.Material_Name ? 1 : -1);
  }

  addOutsources() {
    this.outsources().push(this.newOutsources());
    this.outsourcelist.push(null);
  }

  scan(){
    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
    this.scanning = "Scanning......";
  }

  removeOutsource(i: number) {
    if(!this.isScanned){
      this.outsources().removeAt(i);
      this.outsourcelist.splice(i, 1);
    }
    else{
      this.isScanned = false;
    }   
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async confirm() {
    const outsources = this.addForm.get('outsources').value;

    if(outsources == null || outsources.length <= 0){
      this.toast.error('Need At Least one outsource record', 'Please Add');
      return;
    }

    const addPOModel = {
      POName: '',
      supplier: this.addForm.get('outsourceSupplierControl').value,
      outsourceType: this.addForm.get('outsourceControl').value,
      receivedDate: this.addForm.get('receivedDate').value.toISOString(),
      status: 'Pending',
      soNumber: '',
      poCustomer: '',
      outsources: []
    };

    if(this.update){
      addPOModel.POName = this.po.PO_No;
    }
    else{
      const currentTime = new Date();
      var snapshot = await this.db.database.ref('DefaultTDO').once('value');
  
      if (snapshot.exists() && this.dateFormat.monthDiff(new Date(snapshot.child('currentDate').val()), currentTime) <= 0) {
        const newPO = this.dateFormat.convertDateIntoYearTwoDigitMonth(new Date(snapshot.child('currentDate').val())) + this.padLeadingZeros(snapshot.child('currentNum').val(), 4);
        this.db.database.ref('DefaultTDO/currentNum').set((parseInt(snapshot.child('currentNum').val()) + 1).toString());
        addPOModel.POName = newPO;
      }
      else{
        this.db.database.ref('DefaultTDO/currentDate').set(this.dateFormat.convertDateIntoISO8601NoDay(currentTime));
        this.db.database.ref('DefaultTDO/currentNum').set("2");
  
        const newPO = this.dateFormat.convertDateIntoYearTwoDigitMonth(new Date(currentTime)) + '0001';
        addPOModel.POName = newPO;
      }
    }
    
    var flag = true;
    var count = outsources.length;

    await outsources.forEach(async (element, index) => {
      if (!element.partName || !element.quantity || !element.outsourceNameControl || !element.prDate) {
        this.toast.error('Outsource information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag=false;
      }

      if(element.project != null && element.project != ''){
        var soNoSO_NO = await this.matSelectPO.find(a=>a.JO_No == element.project);

        if(soNoSO_NO != null){
          var SO_Details = await this.po_controller.search_PO_By_Id(soNoSO_NO.SO_No);
          addPOModel.poCustomer = SO_Details.Customer;
          addPOModel.soNumber =SO_Details.PO_No;
        }
      }

      var jo = addPOModel.POName + "-" + count.toString();
      var so = "";

      if(!element.project){
        count++;
      }
      else{
        var soNo_NO = this.cloneSelectPO.find(a=>a.JO_No==element.project);

        if(soNo_NO != null){
          so = soNo_NO.SO_No;
        }
      }

      const info = {
        orderID: (this.update && this.idLists[index] != null) ? this.idLists[index] : uuidv4(),
        joNo: element.project || jo,
        soNo: so,
        partNum: this.partList[index],
        partNo: element.partName,
        partUOM: this.partUomList[index],
        materialID: this.outsourcelist[index].Material_ID,
        materialName: this.outsourcelist[index].Material_Name,
        quantity: element.quantity,
        tdoDate: element.prDate,
        remark: element.remark,
        supplier: this.addForm.get('outsourceSupplierControl').value,
        status: 'Pending'
      };

      addPOModel.outsources.push(info);
    });

    if(!flag)
      return;

    if (!addPOModel.POName ) {
      this.toast.error('Please fill in the TDO No', 'Please fill in');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this PR?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if(this.update){
          this.PORawController.edit_TDO(addPOModel, this.email, "Outsource");
        }
        else{
          this.PORawController.add_TDO(addPOModel, this.email, "Outsource");
        }
        
        this.dialogRef.close(true);
      }
    });
  }

  padLeadingZeros(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getOutsourcess(raw: any, formindex:0) {
    if (raw) {
      this.outsourcelist[formindex] = raw;
    }
  }

  async joChange(event, formindex:0){
    let jo = await this.matSelectPO.find(a=>a.JO_No == event.value);
    //let time = this.addForm.get('receivedDate').value;
    //time.setDate(time.getDate() + parseInt(this.Rule));
    this.partList[formindex] = jo.PO_Part_ID;
    this.partUomList[formindex] = jo.UOM;
    ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('partName').patchValue(jo.PO_Part_No);
    ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('prDate').patchValue(this.requiredDate);
  }

  async partNoChange(event, formindex:0){
    if(!$('#project' + formindex).val()){
      this.partList[formindex] = uuidv4();
      this.partUomList[formindex] = "";

      var childSnapshot = await this.db.database.ref('Part').once('value');

      if (childSnapshot.exists()) {
        var get_Part_No = childSnapshot.child('Part No').val();;
        if (event.target.value.match(get_Part_No)) {
          this.partList[formindex] = childSnapshot.key;
        }
      }
    }
  }

  async barcode(event){
    this.isScanned = true;
    let jo = await this.matSelectPO.find(a=>a.JO_No == event.target.value);
    //let time = this.addForm.get('receivedDate').value;
    //time.setDate(time.getDate() + parseInt(this.Rule));
    this.outsources().push(this.newOutsourcesBarcode(event.target.value, jo.PO_Part_ID, jo.UOM, jo.PO_Part_No, jo.POQuantity, this.requiredDate));
    this.outsourcelist.push(null);

    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
  }

  async getOutsourcesName(sup: Supplier){
    this.matOutsourcesRaw = await this.DetailsOutsources.filter(a=>a.Raw_Type == sup.OutsourceTypes);
    this.cloneOutsourcesRaw = this.matOutsourcesRaw.slice();
    await this.matOutsourcesRaw.sort((a, b) => a.Material_Name > b.Material_Name ? 1 : -1);
    this.Type = sup.OutsourceTypes;
    this.Rule = await this.rawTypes.find(a=>a.value == sup.OutsourceTypes).rule;
    let time = this.addForm.get('receivedDate').value;
    this.requiredDate.setDate(time.getDate() + parseInt(this.Rule));
    this.addForm.controls.outsourceControl.setValue(this.Type);
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }
}
