import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Part } from '../Object_Classes/Part/Part';

@Injectable({
  providedIn: 'root'
})
export class PartServices {

  private partlist = new BehaviorSubject<Part[]>([]);

  currentPart = this.partlist.asObservable();
  constructor() { }

  SetPart(part: Part[]) {
    this.partlist.next(part);
  }

}
