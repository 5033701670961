import { ConfirmationDialogComponent } from './../../../Shared/confirmation-dialog/confirmation-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFireDatabase } from '@angular/fire/database';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { AngularFireStorage } from '@angular/fire/storage';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { UpdateQuantityComponent } from './update-quantity/update-quantity.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize } from 'rxjs/operators';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';

@Component({
  selector: 'app-update-schedule',
  templateUrl: './update-schedule.component.html',
  styleUrls: ['./update-schedule.component.css']
})
export class UpdateScheduleComponent implements AfterViewInit {
  partController = new PartDB_controller(this.db, this.storage,this.firestore);
  PODB_controller = new PODB_controller(this.db);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  private dateFormat = new DateFormatService();

  id: any;
  machine: any;
  scheduleInformation: any;
  //ownerId: any;
  po: any;
  jo: any;
  part: any;
  partID: any;
  partInfo: any;
  scheduleId: any;
  status: any;
  typeOfWO: any;
  disabled: boolean;
  popUp: boolean = false;
  email:string;
  staffId:string;
  disabledPop = true;
  drawing = false;
  public minDate: Date;
  public maxDate: moment.Moment;
  formvalid = true;
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.angularFireDatabase, this.firestore);

  constructor(
    private dialogRef: MatDialogRef<UpdateScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private angularFireDatabase: AngularFireDatabase,
    private dialog: MatDialog,
  ) {
    this.id = data.id;
    let obj = JSON.parse(data.info);
    this.machine = obj.machine;
    this.po = obj.po;
    this.jo = obj.jo;
    this.part = obj.part;
    this.partID = obj.partID;
    //this.scheduleId = obj.scheduleId;
    //this.ownerId = obj.staffId;
    this.minDate = new Date();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });

    this.StaffDB_controller.getStaffList().then(async data => {
      await data.forEach(staff=>{
        if(staff.Email == this.email){
          this.staffId = staff.StaffID;
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.spinner.show();
    this.getInfo();
  }

  async getInfo() {
    const datenow = new Date();
    const snapshot = await this.db.database.ref('User/' + this.staffId).once('value');
    const snapshotpo = await this.db.database.ref('Purchase Order/' + this.po + '/Part List').once('value');

    this.scheduleInformation = {
      key: snapshot.key,
      ...snapshot.val(),
    };
    
    snapshotpo.forEach(e=>{
      if(e.key === this.id){
        this.scheduleInformation['PO No'] = this.po;
        this.scheduleInformation['JO No'] = this.jo;
      }
    });

    this.spinner.hide();
    this.partController.search_Part(this.partID).then(data => {
      this.partInfo = data;
      this.scheduleInformation['Part No'] = data.Part_No;
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(machine) {
    this.dialogRef.close(machine);
  }

  update() {
    /*if (this.status == null || (this.status == 'Drawing Reviewed' && (this.partInfo.DrawingURL == null || this.partInfo.DrawingURL == ''))) {
      this.toast.warning("To complete the JO must have drawing, Please upload drawing");
      return;
    }*/

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm to update the schedule';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        const poinfo = {};
        var current = new Date().getTime().toString();

        if(this.status == 'Drawing Reviewed'){
          poinfo['Status'] = 'Drawing Reviewed';
          poinfo['Schedule/' + current + '/Process'] = 'Drawing Reviewed';
          poinfo['Schedule/' + current + '/Completed By'] = this.staffId;
          poinfo['Schedule/' + current + '/Created_By'] = this.email;

          let updates2 = {};
          updates2['_Status'] = "Completed";
          this.scheduleId = uuidv4();
          await this.db.database.ref('User/'+this.staffId+'/Schedule/'+this.scheduleId+'/').update(updates2);

          let updates3 = {};
          const todayDate = this.getTodayDate();
          let childSnapshot = await this.db.database.ref('Schedule/'+todayDate+'/'+this.staffId).once('value');
  
          if (childSnapshot.exists()) {
            let quantity = parseInt(childSnapshot.child('output').val());
            updates3[todayDate+'/'+this.staffId+'/output'] = (quantity + 1);
            await this.db.database.ref('Schedule/').update(updates3);
          }
          else{
            updates3[todayDate+'/'+this.staffId+'/output'] = 1;
            await this.db.database.ref('Schedule/').update(updates3);
          }
        }
        else if(this.status == 'Drawing Review'){
          poinfo['Status'] = 'Reviewing Drawing';
          poinfo['Schedule/' + current + '/Process'] = 'Reviewing Drawing';
          poinfo['Schedule/' + current + '/Created_By'] = this.email;
        }
        else if(this.status == 'Pending Customer Soft Copy'){
          poinfo['Status'] = 'Pending Customer Soft Copy';
          poinfo['Schedule/' + current + '/Process'] = 'Pending Customer Soft Copy';
          poinfo['Schedule/' + current + '/Created_By'] = this.email;
        }
        else{
          poinfo['Status'] = "Pending Customer Confirmation";
          poinfo['Schedule/' + current + '/Process'] = 'Pending Customer Confirmation';
          poinfo['Schedule/' + current + '/Created_By'] = this.email;
        }
        
        await this.db.database.ref('Purchase Order/' + this.po +'/Part List/' + this.jo).update(poinfo);

        if(this.partInfo.DrawingURL != null && this.partInfo.DrawingURL != ''){
          if (typeof (this.partInfo.DrawingURL) !== 'string') {
            var n = Date.now();
            var filePath = `PartsImages/${n}`;
            var fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(`PartsImages/${n}`, this.partInfo.DrawingURL);
            task.snapshotChanges().pipe(
              finalize(() => {
                var downloadURL = fileRef.getDownloadURL();
                downloadURL.subscribe(async url => {
                  if (url) {
                    const photo = {}
                    this.partInfo.DrawingURL = url;
                    photo['DrawingURL'] = this.partInfo.DrawingURL;
                    await this.db.database.ref('Part/' + this.partID +'/').update(photo);
                  }
                });
              })
            ).subscribe(url => {
              if (url) {
                console.log(url);
              }
            });
          }
          else {
            const photo = {}
            photo['DrawingURL'] = this.partInfo.DrawingURL;
            await this.db.database.ref('Part/' + this.partID +'/').update(photo);
          }
        };

        if(this.partInfo.UnfoldURL != null && this.partInfo.UnfoldURL != ''){
          if (typeof (this.partInfo.UnfoldURL) !== 'string') {
            var n = Date.now();
            var filePath = `PartsImages/${n}`;
            var fileRef = this.storage.ref(filePath);
            const task = this.storage.upload(`PartsImages/${n}`, this.partInfo.UnfoldURL);
            task.snapshotChanges().pipe(
              finalize(() => {
                var downloadURL = fileRef.getDownloadURL();
                downloadURL.subscribe(async url => {
                  if (url) {
                    const photo = {}
                    this.partInfo.UnfoldURL = url;
                    photo['UnfoldURL'] = this.partInfo.UnfoldURL;
                    photo['Drawing Number'] = this.partInfo.Part_No;
                    await this.db.database.ref('Part/' + this.partID +'/').update(photo);
                  }
                });
              })
            ).subscribe(url => {
              if (url) {
                console.log(url);
              }
            });
          }
          else {
            const photo = {}
            photo['DrawingURL'] = this.partInfo.DrawingURL;
            await this.db.database.ref('Part/' + this.partID +'/').update(photo);
          }
        };

        if(this.typeOfWO != null && this.typeOfWO != ''){
          const tow = {}
          tow['typeOfWork'] = this.typeOfWO;
          await this.db.database.ref('Part/' + this.partID +'/').update(tow);
        }

        this.spinner.hide();
        this.dialogRef.close(true);
      }
    })
  }
  
  change(status: string) {
    if(status == 'Drawing Reviewed'){
      this.drawing = true;
      this.status = status;
    }
    else{
      this.drawing = false;
      this.status = status;
    }
  }

  changeTOF(status: string) {
    if(status == 'Drawing Reviewed'){
      this.typeOfWO = status;
    }
    else{
      this.typeOfWO = status;
    }
  }

  ChangeDate() {
    const duration = this.scheduleInformation.endAt.getTime() - this.scheduleInformation.startAt.getTime();
    this.scheduleInformation.end = new Date(this.scheduleInformation.start.getTime() + duration);
    var mac = null;
    this.db.database.ref('Machine').child(this.machine).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.scheduleInformation.start, this.scheduleInformation.end, mac, this.scheduleInformation.key)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid = false
          return;
        }
        else {
          this.formvalid = true;
        }
      }
    })

  }

  estimateTime() {
    let value = (this.partInfo.Cycle_Time * (this.scheduleInformation['Expected Quantity'] / this.partInfo.Number_Cavity)) / 3600;
    this.scheduleInformation.end = new Date(this.scheduleInformation.start.getTime() + value * 60 * 60000);
    var mac = null;

    this.db.database.ref('Machine').child(this.machine).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.scheduleInformation.start, this.scheduleInformation.end, mac, this.scheduleInformation.key)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid = false
          return;
        }
        else {
          this.formvalid = true;
        }
      }
    })
  }

  delete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this schedule?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await this.PODB_controller.delete_Schedule(this.po, this.machine, this.id, this.scheduleInformation);
        this.dialogRef.close(true);
      }
    })
  }

  getClass(){
    if(this.scheduleInformation['LinkedPart2'] && this.scheduleInformation['LinkedPart3'] ){
      return "col-4"
    }else if(this.scheduleInformation['LinkedPart2'] || this.scheduleInformation['LinkedPart3'] ){
      return "col-6"
    }else{
      return "col-12"
    }
  }

  onFileSelected(event) {
    this.partInfo.DrawingURL = event.target.files[0];
  }

  onFileSelected2(event) {
    this.partInfo.UnfoldURL = event.target.files[0];
  }

  getTodayDate(): string {
    const today = new Date();
  
    // Extract year, month, and day components
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    const day = today.getDate().toString().padStart(2, '0');
  
    // Concatenate components in yyyyMMdd format
    const formattedDate = `${year}${month}${day}`;
  
    return formattedDate;
  }
}
