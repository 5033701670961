import { SupplierDB_controller } from '../../../Services/DB_Controller/SupplierDB_controller';
import { RawMaterialInfo } from '../../../Services/Object_Classes/RawMaterial/RawMaterial';
import { RawDB_controller } from '../../../Services/DB_Controller/RawDB_controller';
import { RawMaterialOrder } from '../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { v4 as uuidv4 } from 'uuid';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { toHexString } from 'pdf-lib';

@Component({
  selector: 'app-scan-drawing',
  templateUrl: './scan-drawing.component.html',
  styleUrls: ['./scan-drawing.component.css']
})
export class ScanDrawingComponent implements OnInit {
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;

  po_controller: PODB_controller = new PODB_controller(this.db);

  soList: any = [];
  partList: any = [];
  endDateList: any = [];
  outsourcelist: RawMaterialInfo[] = [];
  matOutsourcesRaw: any = [];
  matSelectPO: PartTracker[] = [];
  cloneSelectPO: PartTracker[] = [];

  addForm: FormGroup;
  isScanned = false;

  email: string;
  scanning: any = '';

  constructor(
    private dialogRef: MatDialogRef<ScanDrawingComponent>,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private dateFormat: DateFormatService
  ) {
    this.addForm = this.fb.group({
      outsources: this.fb.array([]),
    });

    this.angularFireAuth.authState.subscribe(auth=>{this.email = auth.email;})
  }

  ngOnInit() {
    this.spinner.show();

    this.po_controller.getJOList().then(data => {
      this.matSelectPO = data;
      this.cloneSelectPO = this.matSelectPO.slice();
      this.spinner.hide();
    });
  }

  outsources(): FormArray {
    return this.addForm.get('outsources') as FormArray;
  }

  newOutsourcesBarcode(joNo: any, SO_No: any, partNumber: any, name: any, quant: any, endDate: any): FormGroup {
    const projectFilterControl = new FormControl();
    this.partList.push(partNumber);
    //this.endDateList.push(endDate);
    this.soList.push(SO_No);

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    this.scrollToBottom();

    return this.fb.group({
      projectFilterControl,
      project: joNo,
      partName: name,
      soQuantity: "",
      //quantity: quant
    });
  }

  newOutsources(): FormGroup {
    const projectFilterControl = new FormControl();

    projectFilterControl.valueChanges.subscribe(() => {
      this.findJO(projectFilterControl);
    });

    this.scrollToBottom();

    return this.fb.group({
      projectFilterControl,
      project: "",
      partName: "",
      soQuantity: "",
      //quantity: 0
    });
  }

  addOutsources() {
    this.outsources().push(this.newOutsources());
    this.outsourcelist.push(null);
  }

  scan(){
    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
    this.scanning = "Scanning......";
  }

  removeOutsource(i: number) {
    if(!this.isScanned){
      this.outsources().removeAt(i);
      this.outsourcelist.splice(i, 1);
    }
    else{
      this.isScanned = false;
    }   
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async confirm() {
    const outsources = this.addForm.get('outsources').value;

    if(outsources == null || outsources.length <= 0){
      this.toast.error('Need At Least Start One JO', 'Please Add');
      return;
    }

    const addPOModel = {
      outsources: []
    };
    
    var flag = true;
    await outsources.forEach(async (element, index) => {
      if (!element.project || !element.partName) {
        this.toast.error('JO information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag=false;
      }

      const info = {
        SO_No: this.soList[index],
        JO_No: element.project,
        PO_Part_No: this.partList[index],
        PO_Part_Name: element.partName,
        //SoQuantity: element.soQuantity,
        //POQuantity: element.quantity,
        //endDate: this.endDateList[index],
        status: 'Drawing Released'
      };

      addPOModel.outsources.push(info);
    });

    if(!flag)
      return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm these JO have released drwaing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        await addPOModel.outsources.forEach(async machine=>{
          if(machine.JO_No.includes(machine.SO_No)){
            let updates1 = {};
            var current = new Date().getTime().toString();
            updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Status'] = "Drawing Released";
            updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Process'] = "Drawing Released";
            updates1[machine.SO_No + '/Part List/' + machine.JO_No + '/Schedule/' + current + '/Created_By'] = this.email;
            this.db.database.ref('Purchase Order/').update(updates1);
          }
        });

        this.dialogRef.close(true);
      }
    });
  }

  findJO(supplier) {
    if (!this.cloneSelectPO) { return; }
    const search = supplier.value;
    this.matSelectPO = this.cloneSelectPO.filter(p => p.JO_No.toLowerCase().includes(search.toLowerCase()));
  }

  padLeadingZeros(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  async joChange(event, formindex:0){
    let jo = await this.matSelectPO.find(a=>a.JO_No == event.value);

    if(jo && jo.PO_Status != 'Drawing Released'){
      this.partList[formindex] = jo.PO_Part_ID;
      //this.endDateList.push(jo.EndDate);
      this.soList[formindex] = jo.SO_No;
      ((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('partName').patchValue(jo.PO_Part_No);
      //((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('soQuantity').patchValue(jo.POQuantity);
      //((this.addForm.get('outsources') as FormArray).at(formindex) as FormGroup).get('quantity').patchValue(jo.POQuantity);
    }
    else{
      this.toast.error('This JO already released drawing');
    }
  }

  async barcode(event){
    this.isScanned = true;
    let jo = await this.cloneSelectPO.find(a=>a.JO_No == event.target.value);

    if(jo && jo.PO_Status != 'Drawing Released'){
      this.outsources().push(this.newOutsourcesBarcode(event.target.value, jo.SO_No, jo.PO_Part_ID, jo.PO_Part_No, jo.POQuantity, jo.EndDate));
      this.outsourcelist.push(null);
    }
    else{
      this.toast.error('This JO already released drawing');
    }

    
    $('#barcodeScan').val('');
    $('#barcodeScan').trigger('focus');
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
  }
}
