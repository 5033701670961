import { NgxSpinnerService } from 'ngx-spinner';
import { EnterReceiveDetailsComponent } from './enter-receive-details/enter-receive-details.component';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSelectionListChange, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { OrderRawMaterialComponent } from '../order-raw-material/order-raw-material.component';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import jsPDF from 'jspdf';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-received-order',
  templateUrl: './received-order.component.html',
  styleUrls: ['./received-order.component.css']
})
export class ReceivedOrderComponent implements OnInit {

  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  email: string;
  POlist: PurchaseOrderRaw[];
  matSelectPO: PurchaseOrderRaw[] = [];
  formGroup = new FormGroup({});
  selectedPO: PurchaseOrderRaw;
  selectedRaws = [];
  detailsPO = [];
  searchPO: any;
  check = false;
  qrCodes = [];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  poNo: string;

  constructor(
    private dialogRef: MatDialogRef<OrderRawMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })

    this.PORawController.search_PO_withStatusPending().then( data => {
      this.POlist = data;
      this.matSelectPO = this.POlist.slice();
      this.spinner.hide();
    })

    const POControl = new FormControl();
    const POfilterControl = new FormControl();

    POfilterControl.valueChanges.subscribe(() => {
      this.findPO(POfilterControl);
      if (this.POlist) {
        this.check = true;
      }
    });

    POControl.valueChanges.subscribe(() => {
      if (POControl.value) {
        this.searchPO = POControl.value;
        this.check = true;
      }
    });

    this.formGroup = new FormGroup({
      POControl,
      POfilterControl
    });

    this.poNo = data.PO_No;
    this.selectedPO = data;
    this.check = true;
    $('#POControl').hide();
  }

  findPO(PO) {
    if (!this.POlist) { return; }
    const search = PO.value;
    this.matSelectPO = this.POlist.filter(p => p.PO_No.toLowerCase().includes(search.toLowerCase()));
    this.selectedPO = this.matSelectPO.find(p => p.PO_No.toLowerCase() === (this.searchPO.toLowerCase()));
  }

  ngOnInit() {
    this.spinner.show();
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm received this PO and selected raw materials?';

    let statusPO = 'Pending';
    let completedDate = null;

    let count = 0;

    const length = this.selectedPO.PO_RawMaterials.length;
    this.detailsPO.forEach((element, index) => {
      this.selectedPO.PO_RawMaterials.filter(r => r.PO_RawMaterial_ID != element.ID);
      if (element.status === 'Completed') {
        count++;
      }
    });

    this.selectedPO.PO_RawMaterials.forEach(element => {
      if (element.PO_RawMaterial_Status === 'Completed') {
        count++;
      }
    })
    if(count === length) {
      statusPO = 'Completed';
      completedDate = new Date();
    }
    const updatePO={
      selectedPO: this.selectedPO,
      selectedRaws: this.selectedRaws.slice(),
      detailsRaws: this.detailsPO.slice(),
      status: statusPO,
      Completed_Date: completedDate
    }
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        setTimeout(() => {
          this.PORawController.update_PO(updatePO, this.email);
          this.spinner.hide();
          this.dialogRef.close(true);
        }, 1000);
      }
    });
  }

  enterDetails(event : MatSelectionListChange) {
    if(event.option.selected) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = '60%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data =
      {
        event: event.option.value,
      };
      this.dialog.open(EnterReceiveDetailsComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          this.detailsPO.push(result);
        } else {
          event.option.selected = false;
        }
      });
    } else{
      this.removeDetails(event.option.value);
    }
  }

  removeDetails(raw){
    this.detailsPO = this.detailsPO.filter(d => d.ID !== raw.PO_RawMaterial_ID);
  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  view(event,r,d){

    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '60%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      data: r,
      value:d,
      status: 'View'
    };
    this.dialog.open(EnterReceiveDetailsComponent, dialogConfig)
  }

}
