import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, PageEvent, Sort } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';

@Component({
  selector: 'app-mold',
  templateUrl: './mold.component.html',
  styleUrls: ['./mold.component.css']
})
export class MoldComponent implements OnInit {

  email: string;
  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private excelHelper: ExcelHelperService,

  ) {

  }
  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.spinner.show();
  }

  refresh(type){
    switch(type){
      case 'Condition': this.initConditionReport();break;
      case 'Maintenance': this.initMaintenanceReport(); break;
    }
  }

  initConditionReport(){

  }

  initMaintenanceReport(){

  }


}
