import { SupplierDB_controller } from '../../../Services/DB_Controller/SupplierDB_controller';
import { RawMaterialInfo } from '../../../Services/Object_Classes/RawMaterial/RawMaterial';
import { RawDB_controller } from '../../../Services/DB_Controller/RawDB_controller';
import { RawMaterialOrder } from '../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { v4 as uuidv4 } from 'uuid';
import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';

@Component({
  selector: 'app-order-outsource',
  templateUrl: './order-outsource.component.html',
  styleUrls: ['./order-outsource.component.css']
})
export class OrderOutsourceComponent implements OnInit {
  poNo: any;
  soNo: any;
  joNo: any = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  part: Part = new Part();
  machine: Machine = new Machine();
  partlist: Part[] = [];
  partDB_controllers: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  PORawController: PORawDB_controller = new PORawDB_controller(this.db, this.firestore);
  RawDbController: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  supplierDbController: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);
  po_controller: PODB_controller = new PODB_controller(this.db);

  rawlist: RawMaterialInfo[] = [];
  outsourcelist: RawMaterialInfo[] = [];
  matSelectRaw: RawMaterialInfo[] = [];
  matOutsourcesRaw: RawMaterialInfo[] = [];
  matSelectPO: PurchaseOrder[] = []; 
  ClonePOList: PurchaseOrder[] = []; 
  clone = [];
  outcomeClone = [];
  cloneSup:Supplier[] = [];
  cloneOutsourceSup:Supplier[] = [];
  JOList: PartTracker[] = [];

  prDate='';
  choosingRaw = [];
  choosingOutsources = [];

  addForm: FormGroup;
  newPO: PurchaseOrderRaw = new PurchaseOrderRaw();
  newPO_RawMaterial: RawMaterialOrder[] = [];
  startDate = [];
  endDate = [];
  searchRaw: any;
  check = false;
  supplier: Supplier[] = [];
  outsourceSupplier: Supplier[] = [];
  ClonePartList: Part[] = [];
  existingName = false;

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  minDate :Date;
  email: string;
  infolist = [];
  amountlist=[];
  constructor(
    private dialogRef: MatDialogRef<OrderOutsourceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private storage: AngularFireStorage
  ) {
    this.minDate = new Date();

    if(data){
      this.addForm = this.fb.group({
        project: data.JO_No,
        poCus: data.Customer_Name,
        soNo: data.SO_No,
        partNo: data.PO_Part_No,
        quantity: data.POQuantity,
        prDate: new Date(),
        outsources: this.fb.array([]),
      });

      this.soNo = data.SO_No;
      this.joNo = data.JO_No;
    }
    else{
      this.addForm = this.fb.group({
        project: '',
        poCus: '',
        soNo: '',
        partNo: '',
        quantity: 0,
        prDate: new Date(),
        outsources: this.fb.array([]),
      });
    }
    
    this.angularFireAuth.authState.subscribe(auth=>{this.email = auth.email;})
  }

  ngOnInit() {
    this.spinner.show();

    this.po_controller.getPOList().then(data => {
      this.matSelectPO = data;
      this.ClonePOList = this.matSelectPO.slice();
      this.spinner.hide();

      if(this.soNo != '' && this.joNo != ''){
        const po = this.matSelectPO.find(p => p.PO_No === this.soNo);

        if (po) {
          this.loadJO(po);
        }
      }
    });

    this.RawDbController.getRawList().then(data => {
      this.matSelectRaw = data;
      this.clone = this.matSelectRaw.slice();
      this.spinner.hide();
    });

    this.RawDbController.getOutsourceList().then(data => {
      this.matOutsourcesRaw = data;
      this.outcomeClone = this.matOutsourcesRaw.slice();
      this.spinner.hide();
    });

    this.supplierDbController.getRawMatSupplierList().then(data => {
      this.supplier = data;
      this.cloneSup = this.supplier.slice();
      this.spinner.hide();
    });

    this.supplierDbController.getOutsourceSupplierList().then(data => {
      this.outsourceSupplier = data;
      this.cloneOutsourceSup = this.outsourceSupplier.slice();
      this.spinner.hide();
    });

    this.partDB_controllers.getPartList().then(data => {
      this.partlist = data;
      this.ClonePartList = this.partlist.slice();
      this.spinner.hide();
    });
  }
  outsources(): FormArray {
    return this.addForm.get('outsources') as FormArray;
  }

  newOutsources(): FormGroup {
    const outsourceControl = new FormControl();
    const outsourcefilterControl = new FormControl();
    outsourcefilterControl.valueChanges.subscribe(() => {
      this.findRaw(outsourcefilterControl);
      if (this.clone) {
        this.check = true;
      }

    });
    outsourceControl.valueChanges.subscribe(() => {
      if (outsourceControl.value) {
        this.searchRaw = outsourceControl.value;
        this.check = true;
      }
    });

    const outsourceSupplierControl = new FormControl();
    const outsourceSupplierfilterControl = new FormControl();
    outsourceSupplierControl.valueChanges.subscribe(() => {
      this.findSupplier(outsourceSupplierfilterControl);
    });

    return this.fb.group({
      outsourceControl,
      outsourcefilterControl,
      outsourceSupplierControl,
      outsourceSupplierfilterControl,
      outQuantity: 0,
      receivedDate: new Date()
    });
  }

  findRaw(raw) {
    if (!this.clone) { return; }
    const search = raw.value;
    this.matSelectRaw = this.clone.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findSupplier(sup) {
    if (!this.cloneSup) { return; }
    this.supplier = this.cloneSup.filter(p => p.Supplier_Name.toLowerCase().includes(sup.value.toLowerCase()));
  }

  addOutsources() {
    this.outsources().push(this.newOutsources());
    this.outsourcelist.push(null);
  }

  removeOutsource(i: number) {
    this.outsources().removeAt(i);
    this.outsourcelist.splice(i, 1);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async nameValidation() {
    const name =  this.addForm.get('name').value.trim();
    if (name.includes('/') || name.includes('@') || name.includes('[')  || name.includes(']')  || name.includes('.')  || name.includes('#')  || name.includes('$') || name.includes('*') ) {
      this.existingName = true
    }else{
      var snapshot = await this.db.database.ref('PO RawMaterial/'+name).once('value');
      if(snapshot.exists())
        this.existingName = true;
      else
        this.existingName = false;
    }
  }

  async confirm() {
    /*if(this.existingName){
      this.toast.error('Same PO name already been existed', 'Please change');
      return;
    }*/

    const outsources = this.addForm.get('outsources').value;

    if(outsources == null || outsources.length <= 0){
      this.toast.error('Need At Least one outsource record', 'Please Add');
      return;
    }

    const addPOModel = {
      POName: '',
      poCustomer: this.addForm.get('poCus').value.trim(),
      soNumber: this.addForm.get('soNo').value.trim(),
      joNumber: this.addForm.get('project').value.trim(),
      partNo: this.addForm.get('partNo').value.trim(),
      quantity: this.addForm.get('quantity').value,
      prDate: $('#prDate').val(),
      outsources: []
    };

    var snapshot = await this.db.database.ref('miscellaneous').once('value');

    if (snapshot.exists()) {
      addPOModel.POName = snapshot.child('PR No').val();
      this.db.database.ref('miscellaneous/PR No').set((parseInt(snapshot.child('PR No').val()) + 1).toString());
    }

    
    var flag = true;
    
    outsources.forEach((element, index) => {
      if (!this.outsourcelist[index] || !element.outQuantity || !element.outsourceSupplierControl) {
        this.toast.error('Outsource information(' + (index + 1) + ') not completed!', 'Please fill in');
        flag = false;
      }

      const info = {
        materialID: this.outsourcelist[index].Material_ID,
        materialName: this.outsourcelist[index].Material_Name,
        quantity: element.outQuantity,
        supplier: this.cloneOutsourceSup.find(s=>s.Supplier_Name === element.outsourceSupplierControl),
        //unitPrice: this.infolist[index],
        //amount: this.amountlist[index],
        deliveryDate: element.receivedDate,
        status: 'Pending',
        orderID: uuidv4()
      };

      addPOModel.outsources.push(info);
    });

    if(!flag)
      return;

    if (!addPOModel.POName ) {
      this.toast.error('Please fill in the Project name', 'Please fill in');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this PR?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.PORawController.add_PO(addPOModel, this.email, "Outsource");
        this.dialogRef.close(true);
      }
    });

  }

  getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  loadJO(po: PurchaseOrder){
    this.soNo = po.PO_No;
    this.JOList = po.PO_Part_List;

    if(this.joNo != ''){
      this.addForm.controls['project'].setValue(this.joNo);
    }
    
    $('#poCus').val(po.Customer);
  }

  choosePart(jo: PartTracker){
    $('#quantity').val(jo.POQuantity);
    this.partlist = [];

    this.partDB_controllers.getPartList().then(data => {
      data.forEach(data2 => {
        if(data2.Part_No == jo.PO_Part_No){
          this.partlist.push(data2);
          this.addForm.controls['partNo'].setValue(data2.Part_No);
        }
      });
      //this.partlist = data;
    });
  }

  getOutsourcess(raw: any, formindex) {
    const sameRaw = this.choosingOutsources.find(c=>c.raw === raw && c.index !==formindex);

    if(sameRaw){
      this.toast.warning("Same Outsource has been ordered, please choose others", "Warning");
      return;
    }
    if (raw) {
      this.RawDbController.search_OutsourceByName(raw).then(data => {
        this.outsourcelist[formindex] = data;
        const info = {
          raw:raw,
          index: formindex,
        }
        this.choosingOutsources.push(info);
      });
    }
  }
}
