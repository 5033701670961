import { Currency } from './../../../../Shared/Currency';
import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { timeStamp } from 'console';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.css']
})
export class AddSupplierComponent implements OnInit {
  modulesList = ['Raw Material', 'Outsourcing'];
  RawDB_controllers: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  OutsourceList: RawMaterialInfo[] = [];
  CloneOutsourceList: RawMaterialInfo[] = [];
  OutsourceTypeList: any = [];

  supplier = new Supplier();
  currencyList = Currency;
  ngOnInit() {
  }
  constructor(
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    public dialogRef: MatDialogRef<AddSupplierComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data){
        this.supplier.Supplier_ID = data._Supplier_ID;
        this.supplier.Supplier_Name = data._Supplier_Name;
        this.supplier.Person_In_Charge = data._Person_In_Charge;
        this.supplier.Nature = data._Nature;
        this.supplier.Address = data._Address;
        this.supplier.Address2 = data._Address2;
        this.supplier.Address3 = data._Address3;
        this.supplier.Address4 = data._Address4;
        this.supplier.Contact = data._Contact;
        this.supplier.Contact2 = data._Contact2;
        this.supplier.Fax = data._Fax;
        this.supplier.Email = data._Email;
        this.supplier.Last_Updated = data._Last_Updated;
        this.supplier.Created_Date = data._Created_Date;
        this.supplier.Updated_By = data._Updated_By;
        this.supplier.Created_By = data._Created_By;
        this.supplier.Types = data._Types;
        this.supplier.OutsourceTypes = data._OutsourceTypes;
        this.supplier.OutsourceNames = data.OutsourceNames;
      }

      /*this.RawDB_controllers.getOutsourceList().then(data => {
        this.OutsourceList = data;
        this.CloneOutsourceList = this.OutsourceList.slice();
      });*/

      this.RawDB_controllers.getOutsourceTypeList().then(data => {
        this.OutsourceTypeList = data;
      });
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.supplier);
  }

  emailFlag= false;
  phoneFlag= false;

  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  phoneChange(event,model:NgModel){

    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  positionChange(Role){
    console.log(Role);
  }

  filterOut(value: string){
    this.CloneOutsourceList = this.OutsourceList.filter(u =>
      String(u.Raw_Type).toUpperCase().includes(value.toLocaleUpperCase()));
  }
}
