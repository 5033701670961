import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CrushingMaterialDB_controller } from 'src/app/Services/DB_Controller/CrushingMaterialDB_Controller';
import { CrushingMaterial } from 'src/app/Services/Object_Classes/CrushingMaterial/CrushingMaterial';

@Component({
  selector: 'app-view-crushing-material',
  templateUrl: './view-crushing-material.component.html',
  styleUrls: ['./view-crushing-material.component.css']
})
export class ViewCrushingMaterialComponent implements OnInit {

  crushingMaterial = new CrushingMaterial();
  CrushingMaterialDB_controllers: CrushingMaterialDB_controller = new CrushingMaterialDB_controller(this.db, this.firestore);
  crushList = [];
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<ViewCrushingMaterialComponent>,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data) {

     {
       this.crushingMaterial =data;
       this.CrushingMaterialDB_controllers.getCrushCodeList().then(r=>{
        this.crushList = r;
      })
    }
  }


  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.crushingMaterial);
  }

}
