import { QuantityComponent } from './quantity/quantity.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { PartDB_controller } from './../../../../Services/DB_Controller/PartDB_controller';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatSelectionListChange } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-postock',
  templateUrl: './select-postock.component.html',
  styleUrls: ['./select-postock.component.css']
})
export class SelectPOStockComponent implements OnInit {

  part: any;
  PartDB_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  total: number;
  selectedStock: any[] = [];
  totalQuantity: number;
  constructor(
    private dialogRef: MatDialogRef<SelectPOStockComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.total = data.total;
    this.spinner.show();
    this.PartDB_controller.search_PartWithStock(data.partNo).then(part=>{
      this.part = part;
      this.spinner.hide();
    })
  }

  ngOnInit() {
  }

  calculate(){
    this.selectedStock.forEach(element => {
      this.totalQuantity += element.Quantity;
    });
  }

  enterDetails(event: MatSelectionListChange) {
    this.totalQuantity = 0;

    if (event.option.selected) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = '60%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = event.option.value;
      this.dialog.open(QuantityComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          event.option.value.DeductQuantity = result;
          const info = {
            ...event.option.value
          }
          this.selectedStock.push(info);
          this.selectedStock.forEach((element,index) => {
            this.totalQuantity += element.DeductQuantity;
          });
        } else {
          event.option.selected = false;
          this.selectedStock.forEach((element,index) => {
            this.totalQuantity += element.DeductQuantity;
          });
        }
      });
    } else {
      this.removeDetails(event.option.value);
      this.selectedStock.forEach((element,index) => {
        this.totalQuantity += element.DeductQuantity;
      });
    }
  }
  removeDetails(part) {
    this.selectedStock = this.selectedStock.filter(d => d.BoxNumber !== part.BoxNumber);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(){
      if(this.totalQuantity > this.total){
        this.toast.warning('Extra stock has been selected, please check the selection','Warning');
        return;
      }

    this.dialogRef.close(this.selectedStock);
  }
}
