import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { DetailsComponent } from '../details/details.component';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit {

  stock: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<StocksComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService
  ) {

    if (data.type === 'stock') {
      this.db.database.ref('Part/' + data.Part_No).once('value').then(datasnapshot => {
        datasnapshot.child('Stock').forEach(e => {
          const info = {
            id: e.key,
            rackingNo:e.key.split('@@')[2] || '',
            warehouseLocation:e.key.split('@@')[3] || '',
            value: e.val()
          }
          this.db.database.ref('QC/'+e.key.split('@@')[0]).once('value').then(qc=>{
            if(qc.exists()){
              qc.forEach(child=>{
                if(child.key === data.Part_No){
                  info.rackingNo = child.child('Weighting').val()['RackingNo'];
                  info.warehouseLocation = child.child('Weighting').val()['WarehouseLocation'];
                  this.stock.push(info)
                }
              })
            }else{
              this.stock.push(info)
            }
          })
        })
        this.spinner.hide();
      })
    }
    else if (data.type === 'reserved') {
      this.stock = data.stock;
      for (const stk of this.stock) {
        this.db.database.ref('QC/'+stk.id.split('@@')[0]).once('value').then(qc=>{
          if(qc.exists()){
            qc.forEach(child=>{
              if(child.key === data.Part_No){
                stk.rackingNo = child.child('Weighting').val()['RackingNo'];
                stk.warehouseLocation = child.child('Weighting').val()['WarehouseLocation'];

              }
            })
          }
        })
      }
      this.spinner.hide();

    }

  }

  ngOnInit() {
    this.spinner.show();
  }
  cancel() {
    this.dialogRef.close(false);
  }

}
