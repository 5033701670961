import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder }  from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Keyboard from "simple-keyboard";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './pin-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pin-dialog.component.css',
              '../../../../node_modules/simple-keyboard/build/css/index.css',
              ]
})
export class PinDialogComponent implements OnInit {

  form: FormGroup;
  value = "";
  encode_value = "";
  keyboard: Keyboard;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<PinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {}

  onChange = (input: string) => {
    this.encode_value = input.replace(/\d+/g, Array(input.length+1).join("*"));
    this.value = input;
  };

  onKeyPress = (button: string) => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.encode_value);

  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      layout: {
        default: ["1 2 3", "4 5 6", "7 8 9", "0 {backspace}"]
      },
      display: {
        "{backspace}": "clear",
      theme: "hg-theme-default hg-layout-numeric numeric-theme",
      },
    });
    
  }

  ngOnInit(){
    this.form = this.fb.group({
      quantity: ['']
    });
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(this.value);
  }

}
