import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  message: any;
  hidePassword = true;
  hidePassword2 = true;
  hidePassword3 = true;

  loginModel: any = {
    oldpassword:'',
    newpassword:'',
    confirmpassword:'',

  };
  role:string;
  passcodes = ["","","",""];

  hidePasscode = true;
  hidePasscode2 = true;
  hidePasscode3 = true;
  hidePasscode4 = true;
  email:any;

  constructor(
    private dialogRef: MatDialogRef<AccountSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private userinfoSrv:UserInfoService,
    private db: AngularFireDatabase,
  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.userinfoSrv.currentRole.subscribe(role=>this.role = role);
    this.message = data;
  }

  async ngOnInit(){
    const snapshot = await this.db.database.ref('Passcode').once('value');

    if(snapshot.exists()){
      this.passcodes[0] = snapshot.child('Passcode1').val();
      this.passcodes[1] = snapshot.child('Passcode2').val();
      this.passcodes[2] = snapshot.child('Passcode3').val();
      this.passcodes[3] = snapshot.child('Passcode4').val();

    }
  }
  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }

  change(){
    if(this.loginModel.newpassword !== this.loginModel.confirmpassword){
      this.toast.error('New Password not same',"Warning")
      return;
    }

    this.angularFireAuth.authState.subscribe(async authState =>{
      if(authState === null){
      } else{
        const cre = firebase.auth.EmailAuthProvider.credential(authState.email, this.loginModel.oldpassword);
        authState.reauthenticateWithCredential(cre).then(r=>{
          authState.updatePassword(this.loginModel.newpassword).then(update=>{
            this.toast.success('Change password successful',"Success")
            this.dialogRef.close(true);
          })
        }).catch(error=>{
          this.toast.error('Wrong old password',"Failed to change")

        })

      }

    })
  }

  async update(){


    if( (this.passcodes[0] === this.passcodes[1]) ||  (this.passcodes[0] === this.passcodes[2]) || (this.passcodes[0] === this.passcodes[3]) ||
    (this.passcodes[1] === this.passcodes[2]) ||  (this.passcodes[1] === this.passcodes[3]) || (this.passcodes[2] === this.passcodes[3]) ){
      this.toast.warning('Same passcode is detected')
      return;
    }
    const update={
      Passcode1:this.passcodes[0],
      Passcode2:this.passcodes[1],
      Passcode3:this.passcodes[2],
      Passcode4:this.passcodes[3],
      UpdateDate:new Date(),
      UpdatedBy:this.email
    }
    await this.db.database.ref('Passcode').update(update);
    this.toast.success('Update Successful');



  }
}
