export class CrushingMaterial{
  public ID:string;
  public RawMaterial: string;
  public MaterialBatch: string;
  public Pigment: string;
  public Stock: number;
  public _Last_Updated: Date;
  public _Created_Date: Date;
  public _Updated_By: string;
  public _Created_By: string;
  public CrushCode: string;
  public PaletteNumber: string;

}
