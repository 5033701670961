import { Component, ElementRef, Inject, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
  styleUrls: ['./add-staff.component.css']
})
export class AddStaffComponent implements OnInit {

  Staff = new Staff();
  currencyList = Currency;
  submoduleOptions = [];
  createMode =true;
  pass : string;

  //modulesList = ['Quality and Quantity Control', 'Part Management', 'Machine Monitoring', 'PO Management', 'Staff Management'];
  modulesList = ['Quality and Quantity Control', 'Purchase Management', 'Product Management', 'Sales Order Management', 'Manufacturing Management', 'Staff Management', 'Dashboard', 'Scheduling'];
  //positionList = ['ADMIN', 'TECH DEPT', 'QA DEPT', 'LEADER', 'OPERATOR', 'INTERN'];
  positionList = ['Director', 'Supply Chain Officer', 'Adminstrator', 'Sales Executive', 'Operation Manager', 'Engineer', 'Assistant Engineer', 'Staff Engineer Development', 'Production Supervisor', 'Planner', 'QA/QC Engineer', 'Quality Control Engineer', 'General Worker'];

  InterruptCodeList = [
    {
      value: 'Engineering',
      submodule:['Engineering Planning', 'Drawing Released', 'Report', 'Customer Log']
    },
    {
      value: 'Purchase Management',
      submodule:['Purchase Request', 'TDO Request', 'Raw Materials Management', 'Outsourcing Management', 'Supplier Management', 'History']
    },
    {
      value: 'Product Management',
      submodule:['Product Monitoring', 'Product Management']
    },
    {
      value: 'Sales Order Management',
      submodule:['SO Monitoring', 'Sales Order', 'Customer Management','Production Schedule', 'Delivery Schedule', 'Daily Meeting Report']
    },
    {
      value: 'Manufacturing Management',
      submodule:['Monitoring', 'Station Monitoring']
    },
    {
      value: 'Staff Management',
      submodule:['Staff Management', 'Workstation Management']
    },
    {
      value: 'Dashboard',
      submodule:['Dashboard']
    },
    {
      value: 'Quality and Quantity Control',
      submodule:['Scheduling']
    }
  ];
  
  constructor(
    private angularFireAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<AddStaffComponent>,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data) {

    if(data){
      this.createMode  = false;
      this.Staff = data;
    }
  }

  ngOnInit() {
    this.updateSubmodules(this.Staff.Access);
  }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.Staff);
  }

  emailFlag= false;
  passFlag= false;


  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  passwordChange(event, model: NgModel) {
    if(model.value.length < 6){
      model.control.setErrors({ invalid: true });
      this.passFlag = true;

    }else{
      this.passFlag = false;

    }
  }

  positionChange(Role){
    if(Role === 'ADMIN')
      this.Staff.Access = [];
  }

  scanEmployee() {
    this.renderer.setProperty(this.el.nativeElement.querySelector('#barcodeScan'), 'value', '');
    this.renderer.selectRootElement(this.el.nativeElement.querySelector('#barcodeScan')).focus();
    //this.scanning = "Scanning......";
  }

  deleteEmployee(){
    this.Staff.Card_ID = '';
  }

  async barcode(event: any){
    if(event.target.value != null && event.target.value != ''){
      this.Staff.Card_ID = event.target.value;
    }

    //this.scanning = '';
    $('#barcodeScan').val('');
  }

  updateSubmodules(selectedModules: string[]): void {
    // Clear existing submoduleOptions before pushing new options
    this.submoduleOptions = [];
  
    // Update submodule options by pushing each submodule from selected modules
    selectedModules.forEach(selectedModule => {
      const selectedModuleObj = this.InterruptCodeList.find(module => module.value.toString() === selectedModule);
      if (selectedModuleObj) {
        this.submoduleOptions.push(...selectedModuleObj.submodule);
      }
    });
  }  
}
