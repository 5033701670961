import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { SelectMachineSlotComponent } from '../../scheduling/add-event/select-machine-slot/select-machine-slot.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddPartComponent } from '../../parts/PopUpModal/add-part/add-part.component';
import { StaffDB_controller } from 'src/app/Services/DB_Controller/StaffDB_controller';
import { Staff } from 'src/app/Services/Object_Classes/Staff/Staff';

@Component({
  selector: 'app-add-po',
  templateUrl: './add-po.component.html',
  styleUrls: ['./add-po.component.css']
})
export class AddPOComponent implements OnInit {
  poNo: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  part: Part = new Part();
  part_controller: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  po_controller: PODB_controller = new PODB_controller(this.db);
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db,this.firestore);
  StaffDB_controller: StaffDB_controller = new StaffDB_controller(this.db, this.firestore);
  partlist: Part[] = [];
  matSelectCustomer: Customer[] = [];
  customerlist: Customer[] = [];
  matSelectPart: Part[] = [];
  clone = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  choosingPart= [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  newPO: PurchaseOrder = new PurchaseOrder();
  newPO_PartList: PartTracker[] = [];
  searchPart: any;
  email: string;
  existingName = false;
  customerName = '';
  staffName = '';
  assignTo = '';
  check = false;
  cloneCustomer : Customer[] = [];
  Staff: Staff[] = [];
  CloneStaff: Staff[] = [];
  @ViewChild('picker', {static: true}) picker: any;

  public date: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  constructor(
    private dialogRef: MatDialogRef<AddPOComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner : NgxSpinnerService

  ) {

    const customerfilterControl = new FormControl();

    this.addForm = this.fb.group({
      name: '',
      poNumber: '',
      assignTo: '',
      customerfilterControl,
      parts: this.fb.array([]) ,
    });

    customerfilterControl.valueChanges.subscribe(() => {
      this.findCustomer(customerfilterControl);
    });

    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    });

    this.addParts();
  }
  
  async nameValidation() {
    const name = this.addForm.get('name').value.trim();
    if (name.includes('/') || name.includes('@') || name.includes('[')  || name.includes(']')  || name.includes('.')  || name.includes('#')  || name.includes('$') || name.includes('*')) {
      this.existingName = true
    }
    else{
      var snapshot = await this.db.database.ref('Purchase Order/' + name).once('value');
      if (snapshot.exists()) {
        this.existingName = true;
      }
      else {
        this.existingName = false;
        this.poNo = name;
      }
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.clone = this.matSelectPart.slice();
      this.spinner.hide();
    });

    this.CustomerDB_controller.getCustomerList().then(data => {
      this.matSelectCustomer = data;
      this.customerlist = this.matSelectCustomer.slice();
      this.spinner.hide();
    });

    this.StaffDB_controller.getStaffList().then(data => {
      this.Staff = data;
      this.CloneStaff = this.Staff.slice();
      this.spinner.hide();
    });
  }

  findCustomer(cus){
    if (! this.customerlist) { return; }
    const search = cus.value;
    this.matSelectCustomer =  this.customerlist.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));

  }

  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }

  newParts(): FormGroup {

    const partControl = new FormControl();
    const partNameControl = new FormControl();
    const partfilterControl = new FormControl();

    partfilterControl.valueChanges.subscribe(() => {
      this.findPart(partfilterControl);
      if (this.clone) {
        this.check = true;

      }

    });

    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        this.searchPart = partControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partControl,
      partNameControl,
      partfilterControl,
      partNumber: '',
      availableStock: '',
      poquantity: '',
      pos:'',
      reference:'',
      StartDate: new Date(),
      EndDate: new Date(),
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));
  }

  addParts() {
    this.parts().push(this.newParts());
    this.partlist.push(null);
  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.partlist.splice(i, 1);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  addPart(){
    const dialogRefaddPart = this.dialog.open(AddPartComponent, {
      width: '90%',
      height: '90%'
    });

    var newPart = new Part();
    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        newPart = result;
        this.spinner.show();
        this.part_controller.add_or_update_Part(newPart);
        this.toast.success(newPart.Part_Name + " has been added successfully!","Added Successfully");
        this.part_controller.getPartList().then(data => {
          this.matSelectPart = data;
          this.clone = this.matSelectPart.slice();
          this.spinner.hide();
        });
      }
    });
  }

  ChangeDate(index){

  }

  ChangeDate2(index){

  }

  change(cusId){
    this.customerName = cusId;
    /*const d1 = ['STRATUS', 'GREATECH', 'MAXPAX', 'SKY-TAG', 'SIBS', 'SIP', 'VITROX', 'VITRONIC', 'TORAY', 'TT SOLIC', 'NISSEI'];
    const d2 = ['WAFTECH', 'CALTRONIC', 'NSW', 'TELEFLEX', 'YOSOGO', 'S&N', 'BMI', 'BOSTON', 'HEAMONETICS', 'CORTEX', 'LUMILED', 'RTC'];    
    const d3 = ['PENTA', 'SKY-RESOURCES', 'FCK', 'BMI', 'SIN YAN', 'SEA', 'LT BIMETALIC', 'VENTURE', 'REVO', 'ARROW SYSTEM', 'ARMSTRONG', 'POLYPLAS', 'CHI CHENG', 'ENTHALPY', 'ESCATEC', 'FOUNDPAC'];

    if(d1.some(e1 => this.customerName.toUpperCase().includes(e1)))
    {
      this.addForm.get('assignTo').setValue(this.Staff.find(a => a.StaffName.toUpperCase().includes('ATIKAH')).StaffID);
      this.assignTo = this.Staff.find(a => a.StaffName.toUpperCase().includes('ATIKAH')).StaffID;
    }
    else if(d2.some(e2 => this.customerName.toUpperCase().includes(e2)))
    {
      this.addForm.get('assignTo').setValue(this.Staff.find(a => a.StaffName.toUpperCase().includes('AFIQ')).StaffID);
      this.assignTo = this.Staff.find(a => a.StaffName.toUpperCase().includes('AFIQ')).StaffID;
    }
    else if(d3.some(e3 => this.customerName.toUpperCase().includes(e3)))
    {
      this.addForm.get('assignTo').setValue(this.Staff.find(a => a.StaffName.toUpperCase().includes('DZUL')).StaffID);
      this.assignTo = this.Staff.find(a => a.StaffName.toUpperCase().includes('DZUL')).StaffID;
    }*/
  }

  change2(cusId){
    this.staffName = cusId;
  }


  change3(staffId){
    this.assignTo = staffId;
  }

  confirm() {
    if(this.existingName){
      this.toast.error('Please fix PO Name', 'Please change!');
      return;
    }

    if(this.customerName == null || this.customerName == ''){
      this.toast.error('Please Enter Customer', 'Please enter customer!');
      return;
    }

    if(this.staffName == null || this.staffName == ''){
      this.toast.error('Please Enter PIC', 'Please enter person in charge!');
      return;
    }

    const addPOModel = {
      POName : this.addForm.get('name').value.trim(),
      poNumber: this.addForm.get('poNumber').value || "",
      assignTo: this.assignTo,
      CusName : this.customerName,
      staffName : this.staffName,
      createdDate : new Date().toISOString(),
      parts: []
    };

    const parts = this.addForm.get('parts').value;
    parts.forEach((element, index) => {
      if ( !element.poquantity|| !element.partControl || !element.partNameControl  ) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }

      if(element.partControl == null || element.partControl == '' || element.partControl == '-'){
        const info = {
          partNumber: element.partControl,
          partName: element.partNameControl,
          id: uuidv4(),
          poquantity: parseFloat(element.poquantity),
          startDate: element.StartDate,
          endDate: element.EndDate,
          //rawMaterial: this.partlist[index].Raw_Material
        };

        addPOModel.parts.push(info);
      }
      else{
        let part = this.matSelectPart.find(a=>a.Part_No == element.partControl);

        if(part){
          const info = {
            partNumber: element.partControl,
            partName: element.partNameControl,
            id: (part!=null && part.ID!=null && part.ID!="" && element.partControl!=null&& element.partControl!=""&& element.partControl!="-") ? part.ID : uuidv4(),
            poquantity: parseFloat(element.poquantity),
            startDate: element.StartDate,
            endDate: element.EndDate,
            //rawMaterial: this.partlist[index].Raw_Material
          };
  
          addPOModel.parts.push(info);
        }
        else{
          var partId = uuidv4();

          const info = {
            partNumber: element.partControl,
            partName: element.partNameControl,
            id: partId,
            poquantity: parseFloat(element.poquantity),
            startDate: element.StartDate,
            endDate: element.EndDate,
            //rawMaterial: this.partlist[index].Raw_Material
          };
          
          let temp = new Part();
          temp.Part_No = element.partControl;
          temp.ID = partId;
          this.matSelectPart.push(temp);
          addPOModel.parts.push(info);
        }
      }
    });

    if (addPOModel.parts.length === 0) {
      this.toast.error('Please add at least one part', 'Add part');
      return;
    }

    if(addPOModel.parts.length !== this.partlist.length){
      this.toast.error('Form not completed', 'Form');
      return;
    }

    if (!addPOModel.POName) {
      this.toast.error('Please fill in the SO number ', 'Please fill in');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this SO?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.po_controller.addPOWithoutSchedule(addPOModel,this.email);
        this.dialogRef.close(addPOModel);
      }
    });

  }

 getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /*getPart(part,formindex){
    const samePart = this.choosingPart.find(c=>c.part === part && c.index !==formindex);
    
    if(samePart){
      this.toast.warning("Same part number has been ordered, please choose others", "Warning");
      return;
    }

    if (part) {
      this.part_controller.search_Part(part).then(data => {
        this.partlist[formindex] = data;
        const info = {
          part:part,
          index: formindex,
        }
        this.choosingPart.push(info);

      });
    }
  }*/

  getPart(formindex){
    var partNo = $('#partNo').val();
    const samePart = this.matSelectPart.find(c=>c.Part_No === partNo);
    
    if (samePart) {
      this.part_controller.search_Part(partNo.toString()).then(data => {
        this.partlist[formindex] = data;
        const info = {
          part:partNo,
          index: formindex,
        }

        //this.choosingPart.push(info);
      });
    }
  }

  parFloat(value){
    return parseFloat(value);
  }

}
